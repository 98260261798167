"use client";

import isEmpty from "lodash/isEmpty";
import React, { createContext, useCallback, useEffect, useState } from "react";
import useAuditSchema from "hooks/useAuditSchema";
import useSegmentSchema from "hooks/useSegmentSchema";
import useDBSchema from "hooks/useDBSchema";
import { schemaInstance, Schema as SchemaClass } from "utils/schema";
import { getBASchema } from "lib/adminApi";
import { RecordItem } from "types/common";
import { ALTERNATE_SCHEMAS } from "utils/constants";

export interface SchemaState {
  schema: any;
  updateSchema: (schema: any) => void;
  schemaInstance: SchemaClass | null;
  resetSchema: () => void;
  altSchemas: RecordItem;
}

export const SchemaContext = createContext<SchemaState | null>(null);

const { Provider } = SchemaContext;

export const SchemaContextProvider = ({
  children,
  fetchFromCache
}: {
  children: React.ReactNode;
  fetchFromCache?: boolean;
}) => {
  const [schema, setSchema] = useState(null);
  const [altSchemas, setAltSchemas] = useState<RecordItem>({});
  const { data: dbSchemaData } = useDBSchema();
  const { data: auditSchemaData } = useAuditSchema();
  const { data: segmentSchemaData, resetSchema: resetSegmentSchema } = useSegmentSchema(fetchFromCache);

  const updateSchema = useCallback((schema: any) => {
    setSchema(schema);
    schemaInstance.setSchema(schema);
  }, []);

  const addAlternateSchema = useCallback((newSchema: any, schemaName: string) => {
    setAltSchemas((prev) => ({ ...(prev || {}), [schemaName]: { ...newSchema } }));
    schemaInstance.addAlternateSchema(newSchema, schemaName);
  }, []);

  const resetSchema = useCallback(async () => {
    const origin = typeof window !== "undefined" ? window.location.origin : "";
    setAltSchemas((prev) => ({ ...prev, [ALTERNATE_SCHEMAS.SEGMENT_CLEAN]: {} }));
    resetSegmentSchema();
    const updatedSchema = await getBASchema(origin, false, true);
    setSchema(updatedSchema);
  }, [resetSegmentSchema]);

  useEffect(() => {
    if (!dbSchemaData || !isEmpty(schema)) {
      return;
    }

    updateSchema(dbSchemaData);
  }, [dbSchemaData, updateSchema, schema]);

  useEffect(() => {
    if (!auditSchemaData) return;
    const auditTables = Object.keys(auditSchemaData);
    // If audit schema has already been merged, don't merge again
    if (altSchemas?.[ALTERNATE_SCHEMAS.AUDIT]?.[auditTables[0]]) {
      return;
    }

    addAlternateSchema(auditSchemaData, ALTERNATE_SCHEMAS.AUDIT);
  }, [altSchemas, auditSchemaData, addAlternateSchema]);

  useEffect(() => {
    if (!segmentSchemaData) return;
    const segmentCleanTables = Object.keys(segmentSchemaData.definitions);
    // If segment schema has already been merged, don't merge again
    if (altSchemas?.[ALTERNATE_SCHEMAS.SEGMENT_CLEAN]?.[segmentCleanTables[0]]) {
      return;
    }

    addAlternateSchema(segmentSchemaData?.definitions, ALTERNATE_SCHEMAS.SEGMENT_CLEAN);
  }, [altSchemas, segmentSchemaData, addAlternateSchema]);

  return <Provider value={{ schema, updateSchema, schemaInstance, altSchemas, resetSchema }}>{children}</Provider>;
};
