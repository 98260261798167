import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";

import { getAlternateSchema, alternateSchemaCache } from "lib/adminApi";
import { ALTERNATE_SCHEMAS } from "utils/constants";

const useSegmentSchema = (isDisabled?: boolean) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["alternateSchema", ALTERNATE_SCHEMAS.SEGMENT_CLEAN],
    queryFn: () => {
      const origin = typeof window !== "undefined" ? window.location.origin : "";
      return getAlternateSchema(origin, ALTERNATE_SCHEMAS.SEGMENT_CLEAN);
    },
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    gcTime: Infinity,
    staleTime: Infinity,
    retry: false,
    enabled: !isDisabled
  });

  const resetSchema = useCallback(() => {
    delete alternateSchemaCache[ALTERNATE_SCHEMAS.SEGMENT_CLEAN];
    refetch();
  }, [refetch]);

  return { data: data, isLoading, refetch, resetSchema };
};

export default useSegmentSchema;
