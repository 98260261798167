import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getRecordTypes } from "lib/adminApi";

const useRecordTypes = () => {
  const supabaseClient = useSupabaseBrowser();

  const { data, error, isLoading, refetch, isFetched } = useQuery({
    queryKey: ["recordTypes"],
    queryFn: () => getRecordTypes(supabaseClient),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnMount: false
  });

  return {
    data: data?.data,
    error,
    isLoading,
    refetch,
    isFetched
  };
};

export default useRecordTypes;
