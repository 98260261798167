import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { getDerivedFileType } from "utils/file";

import { RecordItem } from "types/common";
import { AddInput } from "types/apiTypes";

import useMultiFilesUpload from "./useMultiFilesUpload";
import useSchemaState from "./useSchemaState";

const useFilesProcessUpload = () => {
  const { startUpload, uploadPercent } = useMultiFilesUpload();
  const { schemaInstance } = useSchemaState();

  const processFilesForUpload = useCallback(
    (
      finalFiles: File[] = [],
      uploadPath = "general",
      createdInProps?: {
        createdIn: string;
        createdInPath: string;
      }
    ) => {
      return finalFiles.map((file) => {
        return {
          file: file,
          path: `${uploadPath}/${uuidv4()}`,
          file_type: getDerivedFileType(file.type),
          name: file.name,
          created_in: createdInProps?.createdIn,
          created_in_path: createdInProps?.createdInPath
        };
      });
    },
    []
  );

  const uploadFiles = useCallback(
    async (
      filesInput: Record<string, File | File[]>,
      uploadPath: string,
      createdInProps?: {
        createdIn: string;
        createdInPath: string;
      }
    ) => {
      const filesToUpload: File[] = [];
      const filesToUploadColumnMap: string[] = [];
      let isFilesListArray = false;
      Object.keys(filesInput).forEach((imageCol: string) => {
        if (Array.isArray(filesInput[imageCol])) {
          // This case handles files list input
          (filesInput[imageCol] as File[]).forEach((file) => {
            if (file instanceof File) {
              filesToUpload.push(file as File);
            }
          });
          filesToUploadColumnMap.push(imageCol);
          isFilesListArray = true;
        } else if (filesInput[imageCol] instanceof File) {
          filesToUploadColumnMap.push(imageCol);
          filesToUpload.push(filesInput[imageCol] as File);
        }
      });

      const finalFilesList = processFilesForUpload(filesToUpload, uploadPath, createdInProps);
      const filePathToColumnMap = !isFilesListArray
        ? finalFilesList.reduce((acc: Record<string, string>, fileInfo, index: number) => {
            acc[fileInfo.path] = filesToUploadColumnMap[index];
            return acc;
          }, {})
        : {
            [filesToUploadColumnMap[0]]: finalFilesList?.map((fileInfo) => fileInfo.path)
          };

      if (finalFilesList.length) {
        const results = await startUpload(finalFilesList);
        if (results.uploadedFiles?.length) {
          return results.uploadedFiles.reduce((acc: RecordItem, uploadedFile) => {
            if (isFilesListArray) {
              if (!acc[filesToUploadColumnMap[0]]) {
                acc[filesToUploadColumnMap[0]] = [];
              }
              acc[filesToUploadColumnMap[0]].push(uploadedFile.id);
            } else {
              const key = (filePathToColumnMap as RecordItem)[uploadedFile.path];
              acc[key] = uploadedFile.id;
            }

            return acc;
          }, {});
        }
      }
    },
    [startUpload, processFilesForUpload]
  );

  const uploadJoinTableFiles = useCallback(
    async (
      filesInput: RecordItem,
      uploadPath: string,
      joinTableLookupNameToTableMap: RecordItem,
      isCreation = false,
      createdInProps?: {
        createdIn: string;
        createdInPath: string;
      }
    ) => {
      const filesToUpload: File[] = [];
      const filesToUploadJoinTableMap: AddInput[] = [];
      const fileWithoutUploadJoinTableMap: AddInput[] = []; // for join table with file column, but no file to upload

      Object.keys(filesInput).forEach((joinTableName: string) => {
        const finalLookupTableName = joinTableLookupNameToTableMap[joinTableName];
        const fileKey = schemaInstance?.extendedSchema[finalLookupTableName].compositePk?.find(
          (key) => key.table === "files"
        )?.attributeId;
        if (Array.isArray(filesInput[joinTableName])) {
          const joinTableInput = [...filesInput[joinTableName]];
          joinTableInput.forEach((compositeKeyInput) => {
            const isFileToUpload = Object.keys(compositeKeyInput).find((key) => compositeKeyInput[key] instanceof File);
            if (isFileToUpload && fileKey) {
              filesToUploadJoinTableMap.push({ tableName: finalLookupTableName, input: compositeKeyInput });
              filesToUpload.push(compositeKeyInput[fileKey] as File);
            } else if (fileKey && isCreation) {
              fileWithoutUploadJoinTableMap.push({
                tableName: finalLookupTableName,
                input: { ...compositeKeyInput, [fileKey]: compositeKeyInput[fileKey].id }
              });
            }
          });
        } else {
          const joinTableInput = { ...filesInput[joinTableName] };
          const isFileToUpload = Object.keys(joinTableInput).find((key) => joinTableInput[key] instanceof File);
          if (fileKey && isFileToUpload) {
            filesToUploadJoinTableMap.push({ tableName: finalLookupTableName, input: joinTableInput });
            filesToUpload.push(joinTableInput[fileKey] as File);
          } else if (fileKey && isCreation) {
            fileWithoutUploadJoinTableMap.push({
              tableName: finalLookupTableName,
              input: { ...joinTableInput, [fileKey]: joinTableInput[fileKey].id }
            });
          }
        }
      });

      const finalFilesList = processFilesForUpload(filesToUpload, uploadPath, createdInProps);
      const filePathToJoinTableMap = finalFilesList.reduce((acc: Record<string, AddInput>, fileInfo, index: number) => {
        acc[fileInfo.path] = filesToUploadJoinTableMap[index];
        return acc;
      }, {});
      const results = await startUpload(finalFilesList);
      if (results.uploadedFiles?.length) {
        return results.uploadedFiles.reduce(
          (acc: AddInput[], uploadedFile) => {
            const fileJoinTableInput = filePathToJoinTableMap[uploadedFile.path];
            const fileKey = Object.keys(fileJoinTableInput.input).find(
              (key) => fileJoinTableInput.input[key] instanceof File
            );
            if (fileKey) {
              acc.push({
                tableName: fileJoinTableInput.tableName,
                input: { ...fileJoinTableInput.input, [fileKey]: uploadedFile.id }
              });
            }
            return acc;
          },
          [...fileWithoutUploadJoinTableMap] // initialize with the files that already were uploaded
        );
      } else {
        return [...fileWithoutUploadJoinTableMap]; // return the files that already were uploaded
      }
    },
    [schemaInstance?.extendedSchema, startUpload, processFilesForUpload]
  );

  return { processFilesForUpload, uploadFiles, uploadJoinTableFiles, uploadPercent };
};

export default useFilesProcessUpload;
