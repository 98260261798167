import { useContext } from "react";
import { UIContext, UIContextState } from "context/UIContext";

const useUIState = () => {
  const contextState = useContext<UIContextState | null>(UIContext);

  if (!contextState || typeof contextState === "undefined" || typeof contextState !== "object") {
    return {
      resetState: () => {},
      uiState: undefined,
      updateUIState: () => {},
      childBackPagePath: undefined,
      updateChildBackPagePath: () => {},
      escapeEventResolvedByComp: undefined,
      setEscapeEventResolvedByComp: () => {},
      showErrorToast: () => {},
      commandMenuState: undefined,
      updateCommandMenuState: () => {},
      setCommandMenuState: () => {},
      testState: undefined,
      updateTestState: () => {},
      setVercelToolbarEnabled: () => {}
    };
  }

  return contextState as UIContextState;
};

export type UIContextType = ReturnType<typeof useUIState>;

export default useUIState;
