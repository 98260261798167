import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { DefaultValue, ExternalDefaultValue, Page, TableColumnType } from "types/baTypes";
import { RecordItem, SelectOption } from "types/common";
import { RelationRecordsData, getFormColumnValue } from "components/Form/utils";
import { isColumnInView, isLookupForeignKeyOnJoinTable } from "./columnUtils";
import { ExtendedSchema, TableSchema } from "./schema";
import { AdditionalFormActions, CellType, CrudActions, LookupTypes, SPECIAL_DEFAULTS, ViewOption } from "./constants";

/**
 *  This method returns the foreign key id on the join table
 *  along with the composite key values on the join table or ID if present
 * @returns {
 *     [foreignKeyId]: string,
 *     compositePrimaryKey: RecordItem
 *   }
 */
export const getForeignKeyOnJoinTableInput = ({
  recordData,
  column,
  formInput,
  extendedSchema
}: {
  recordData: RecordItem;
  column: TableColumnType;
  formInput: RecordItem; // Complete form input, not just the column
  extendedSchema: { [tablename: string]: TableSchema };
}) => {
  const isColForeignKeyOnJoin = isLookupForeignKeyOnJoinTable(column);
  if (!isColForeignKeyOnJoin) return null;

  const { lookupPath } = column;
  const firstLevelDetails = lookupPath?.["0"];
  const secondLevelDetails = lookupPath?.["1"];
  const formInputForColumn = formInput[column.id];
  if (!formInputForColumn) return null;
  const finalInput: RecordItem = {};
  if (firstLevelDetails?.lookupTableName && secondLevelDetails?.lookupForeignKey) {
    const compositePrimaryKey = extendedSchema[firstLevelDetails?.lookupTableName].compositePk;
    const relevantRecord = cloneDeep(
      recordData?.[firstLevelDetails?.lookupColumnLabel || firstLevelDetails?.lookupTableName] || {}
    );
    // There should be an existing entry in the join table to update the foreign key
    if (!isEmpty(relevantRecord) && Array.isArray(relevantRecord)) {
      const firstValue = relevantRecord[0];
      if ((formInputForColumn as SelectOption)?.inputValue?.optionData?.id) {
        finalInput[secondLevelDetails?.lookupForeignKey] = (
          formInputForColumn as SelectOption
        )?.inputValue.optionData.id;
        finalInput["compositePrimaryKey"] = {};
        compositePrimaryKey?.forEach((key) => {
          finalInput["compositePrimaryKey"] = {
            ...finalInput["compositePrimaryKey"],
            [key.attributeId]: firstValue[key.attributeId]
          };
        });
      }
    }
  }
  return finalInput;
};

// ##HARDCODED column names for created in tracking
export const hasCreatedInColumns = (tableProps?: TableSchema) => {
  if (!tableProps?.attributeIds?.length) return false;
  const { attributeIds } = tableProps;
  return attributeIds.includes("created_in") && attributeIds.includes("created_in_path");
};

export const getFormColumns = ({
  pageData,
  columns,
  allPages,
  action,
  pageFormViewId
}: {
  pageData?: Page;
  columns?: TableColumnType[] | null;
  allPages?: Page[] | null;
  action?: AdditionalFormActions | CrudActions;
  pageFormViewId?: string;
}) => {
  const finalColumns = action === AdditionalFormActions.EMAIL ? pageData?.columns || [] : columns || [];
  const sortedColumns = pageFormViewId
    ? sortBy(finalColumns, `customPageViews.${pageFormViewId}.sort`)
    : sortBy(
        finalColumns.filter((column) => isColumnInView(column, ViewOption.FORM)),
        `sortOrder.${ViewOption.FORM}`
      );

  return sortedColumns.reduce((acc: TableColumnType[], column) => {
    if (
      (!pageFormViewId && column?.views?.[ViewOption.FORM].formRelationPageId) ||
      (pageFormViewId && column?.customPageViews?.[pageFormViewId]?.formRelationPageId)
    ) {
      const formRelationPageId = pageFormViewId
        ? column?.customPageViews?.[pageFormViewId]?.formRelationPageId
        : column?.views?.[ViewOption.FORM].formRelationPageId;
      const relationPage = allPages?.find((page) => page.id === formRelationPageId);
      if (relationPage && relationPage.columns) {
        const relationPageFormColumns = sortBy(
          relationPage.columns.filter((col) => col.views?.[ViewOption.FORM]?.id),
          `sortOrder.${ViewOption.FORM}`
        );
        acc.push(...relationPageFormColumns);
      }
    } else {
      acc.push(column);
    }
    return acc;
  }, []);
};

export const getFormColumnInitValue = ({
  action,
  recordData,
  finalRelationRecordsData,
  column,
  isJoinTable,
  parentRecord,
  extendedSchema,
  parentRecordForForeignKey,
  currentUserId,
  currentProjectId,
  currentRecordId,
  results,
  isInlineCreate,
  prefillValues,
  tablePath
}: {
  action?: CrudActions | AdditionalFormActions;
  recordData?: RecordItem;
  finalRelationRecordsData?: RelationRecordsData[] | null;
  column: TableColumnType;
  isJoinTable?: boolean;
  parentRecord?: { name: string; record: RecordItem };
  extendedSchema?: ExtendedSchema;
  parentRecordForForeignKey?: { name: string; record: RecordItem } | null;
  currentUserId?: string;
  currentProjectId?: string;
  currentRecordId?: string;
  results?: RecordItem;
  isInlineCreate?: boolean;
  prefillValues?: RecordItem;
  tablePath?: string;
}) => {
  // Form calls setValue with this initial value
  let columnInitValue = null;
  let isReadOnly = false;
  if (action === CrudActions.UPDATE && recordData && finalRelationRecordsData) {
    columnInitValue = getFormColumnValue(column, recordData, finalRelationRecordsData);
  }
  if (isJoinTable && action === CrudActions.CREATE && parentRecord) {
    if (column.name === parentRecord.name) {
      columnInitValue = parentRecord.record;
    } else if (column.isLookup) {
      const lookupPath = column.lookupPath?.[0];
      if (lookupPath?.lookupType === LookupTypes.JOIN) {
        const compositeKey = extendedSchema?.[lookupPath.lookupTableName].compositePk;
        if (compositeKey?.some((key) => key.attributeId === parentRecord.name)) {
          columnInitValue = {
            inputValue: parentRecord.record,
            displayValue: parentRecord.record
          };
        }
      } else if (lookupPath?.lookupType === LookupTypes.FOREIGN && lookupPath.lookupForeignKey === parentRecord.name) {
        columnInitValue = {
          inputValue: parentRecord.record,
          displayValue: parentRecord.record
        };
      }
    } else {
      if (column.defaultValues?.value) {
        columnInitValue =
          column.type === CellType.BOOLEAN ? column.defaultValues.value === "true" : column.defaultValues.value;
        isReadOnly = true;
      }
    }
  } else if (action === CrudActions.CREATE && parentRecordForForeignKey) {
    if (column.isLookup) {
      const lookupPath = column.lookupPath?.[0];
      if (
        lookupPath?.lookupType === LookupTypes.FOREIGN &&
        lookupPath.lookupForeignKey === parentRecordForForeignKey.name
      ) {
        columnInitValue = {
          inputValue: parentRecordForForeignKey.record,
          displayValue: parentRecordForForeignKey.record
        };
      }
    } else {
      if (column.name === parentRecordForForeignKey.name) {
        columnInitValue = parentRecordForForeignKey.record;
      }
    }
  }
  if (action === CrudActions.CREATE && column.defaultValues) {
    if (column.isLookup) {
      // Add support for lookup foreign key id
      const firstLevel = column.lookupPath?.["0"];
      if (firstLevel?.lookupForeignKey && (column.defaultValues as DefaultValue)?.[firstLevel.lookupForeignKey]) {
        let finalValue = (column.defaultValues as DefaultValue)?.[firstLevel.lookupForeignKey]?.value;
        if (
          (column.defaultValues as DefaultValue)?.[firstLevel.lookupForeignKey]?.value ===
            SPECIAL_DEFAULTS.CURRENT_USER_ID &&
          currentUserId
        ) {
          finalValue = currentUserId;
        }
        if (
          (column.defaultValues as DefaultValue)?.[firstLevel.lookupForeignKey]?.value ===
            SPECIAL_DEFAULTS.CURRENT_PROJECT_ID &&
          currentProjectId
        ) {
          finalValue = currentProjectId;
        }
        if (
          (column.defaultValues as DefaultValue)?.[firstLevel.lookupForeignKey]?.value ===
            SPECIAL_DEFAULTS.CURRENT_RECORD_ID &&
          currentRecordId
        ) {
          finalValue = currentRecordId;
        }
        columnInitValue = {
          inputValue: { id: finalValue },
          displayValue: { id: finalValue }
        };
      }
    } else {
      if (column.defaultValues?.value) {
        if ((column.defaultValues?.value as ExternalDefaultValue)?.isExternalRecord) {
          const externalDefault = column.defaultValues?.value as ExternalDefaultValue;
          const externalRecord = results?.[column.id]?.[0]?.[externalDefault?.columnName];
          if (externalRecord) {
            columnInitValue = externalRecord;
          }
        } else {
          columnInitValue =
            column.type === CellType.BOOLEAN ? column.defaultValues.value === "true" : column.defaultValues.value;
          if (column.defaultValues?.value === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID && currentProjectId) {
            columnInitValue = currentProjectId;
          }
          if (column.defaultValues?.value === SPECIAL_DEFAULTS.CURRENT_RECORD_ID && currentRecordId) {
            columnInitValue = currentRecordId;
          }
        }
      }
    }
  }
  // Currently set up to work for basic column types only
  if (action === CrudActions.CREATE && isInlineCreate && column.defaultValues && column.name) {
    columnInitValue =
      column.type === CellType.BOOLEAN ? column.defaultValues.value === "true" : column.defaultValues.value;
  }
  if (action === CrudActions.CREATE && (prefillValues?.[column.name] || prefillValues?.[column.id])) {
    columnInitValue = prefillValues?.[column.name] || prefillValues?.[column.id];
  }

  if (column.isFormula || (column.isRollup && !column.name)) {
    isReadOnly = true;
  }
  // ##HARDCODED: Special cases to handle email form prefill
  if (tablePath === "emails" && column.type === CellType.PEOPLE && prefillValues?.to_person) {
    columnInitValue = prefillValues.to_person;
  }
  if (tablePath === "emails" && column.type === CellType.COMPANY && prefillValues?.to_company) {
    columnInitValue = prefillValues.to_company;
  }

  return { columnInitValue, isReadOnly };
};
