import { useMutation } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { updateRecordByCompositeKey } from "lib/supabaseApi";
import type { UpdateInput, UpdateResponse } from "types/apiTypes";

const useUpdateRecordByCompositeKey = () => {
  const supabaseClient = useSupabaseBrowser();
  const { mutateAsync, isPending } = useMutation<UpdateResponse, unknown, UpdateInput>({
    mutationFn: ({ tableName, input }: UpdateInput) => updateRecordByCompositeKey({ tableName, input }, supabaseClient)
  });

  return {
    updateRecordByCompositeKeyAsync: mutateAsync,
    isLoading: isPending
  };
};

export default useUpdateRecordByCompositeKey;
