import { useQuery } from "@tanstack/react-query";

import { getBASchemaKvCache } from "lib/adminApi";

const useDBSchema = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["dbSchema"],
    queryFn: () => {
      return getBASchemaKvCache(typeof window !== "undefined" ? window.location.origin : "");
    },
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    gcTime: Infinity,
    staleTime: Infinity,
    retry: false
  });

  return { data: data, isLoading, refetch };
};

export default useDBSchema;
