import { useMutation } from "@tanstack/react-query";
import { PostgrestError } from "@supabase/supabase-js";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import useErrorLogger from "hooks/useErrorLogger";

import { addRecordToTable } from "lib/supabaseApi";
import type { AddInput, AddResponse } from "types/apiTypes";
import { ERROR_TYPES } from "utils/constants";
import useCurrentUser from "./useCurrentUser";

const useAddRecord = () => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { logError } = useErrorLogger();
  const { mutateAsync, isPending } = useMutation<AddResponse, unknown, AddInput>({
    mutationFn: ({ tableName, input }: AddInput) =>
      addRecordToTable(
        {
          tableName,
          input: Array.isArray(input)
            ? input.map((entry) => ({
                ...entry,
                org_id: entry.org_id ? entry.org_id : currentUser?.is_super_admin ? currentUser?.org_id : null
              }))
            : {
                ...input,
                org_id: input.org_id ? input.org_id : currentUser?.is_super_admin ? currentUser?.org_id : null
              }
        },
        supabaseClient
      ),
    onError: (error, variables) => {
      logError({
        error: error as PostgrestError,
        source: "useAddRecord",
        type: ERROR_TYPES.HOOKS,
        message: (error as PostgrestError).message || "Error adding record",
        url: window.location.href,
        additionalInfo: {
          variables
        }
      });
    }
  });

  return {
    addRecordAsync: mutateAsync,
    isLoading: isPending
  };
};

export default useAddRecord;
