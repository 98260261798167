import intersection from "lodash/intersection";
import { RecordItem } from "types/common";
import { LookupTypes } from "./constants";
// Cached for the session

export type FKey = { attributeId: string; table: string; fkAttributeId: string };
export type CompositePKey = { attributeId: string; table: string };

export type JoinTable = {
  tableName: string;
  joinAttributes?: CompositePKey[];
  fkColumns: FKey[];
  tableType: LookupTypes;
};

export type TableColumnProperties = {
  [attributeId: string]: {
    type: string;
    format: string;
    description?: string;
  };
};
export interface TableSchema {
  required: string[];
  properties: TableColumnProperties;
  attributeIds: string[];
  pk: string;
  compositePk?: CompositePKey[];
  fk: FKey[] | [];
  joinTables: JoinTable[] | [];
  schemaName?: string;
}

export type ExtendedSchema = {
  [tableId: string]: TableSchema;
};

export class Schema {
  _schema: any;
  extendedSchema: ExtendedSchema;
  alternateSchema: { [schemaName: string]: ExtendedSchema };

  constructor(schema: any) {
    this._schema = schema;
    this.extendedSchema = {};
    this.alternateSchema = {};
    // Initialize schema properties for easy access
    Object.keys(schema).forEach((tableId) => {
      const table = schema[tableId] as any;
      this.extendedSchema[tableId] = {
        required: table.required,
        properties: table.properties,
        attributeIds: Object.keys(table.properties),
        pk: this._getPrimaryKey(table),
        fk: this._getForeignKeys(table),
        joinTables: []
      };
    });
  }

  setSchema(schema: any) {
    this.addAlternateSchema;
    this.extendedSchema = {};
    // Initialize schema properties for easy access
    Object.keys(schema).forEach((tableId) => {
      const table = schema[tableId] as any;
      this.extendedSchema[tableId] = {
        required: table.required,
        properties: table.properties,
        attributeIds: Object.keys(table.properties),
        pk: this._getPrimaryKey(table),
        compositePk: this._getCompositePrimaryKeys(table),
        fk: this._getForeignKeys(table),
        joinTables: [],
        schemaName: "public"
      };
    });
    // Update join tables
    Object.keys(this.extendedSchema).forEach((tableId) => {
      const joinTables = this._updateJoinTables(tableId, this.extendedSchema[tableId], this.extendedSchema);
      this.extendedSchema[tableId].joinTables = joinTables;
    });
  }

  // All schemas apart from public added here
  addAlternateSchema(newSchema: any, schemaName: string) {
    const schemaTables: RecordItem = {};
    Object.keys(newSchema || {}).forEach((tableId) => {
      const table = newSchema[tableId] as any;
      schemaTables[tableId] = {
        required: table.required,
        properties: table.properties,
        attributeIds: Object.keys(table.properties),
        pk: this._getPrimaryKey(table),
        compositePk: this._getCompositePrimaryKeys(table),
        fk: this._getForeignKeys(table),
        joinTables: [],
        schemaName
      };
    });
    this.alternateSchema = { ...this.alternateSchema, [schemaName]: schemaTables };
  }
  /*
  Retrieve all join tables for the specified table
  */
  _updateJoinTables(tableName: string, table: TableSchema, updatedTableSchema: { [tableId: string]: TableSchema }) {
    const tablesWithFkToTable = Object.keys(updatedTableSchema).filter((tableId) => {
      const table = updatedTableSchema[tableId];
      if (table.fk?.length && table.fk.find((fkEntry) => fkEntry.table === tableName)) {
        return true;
      }
      return false;
    });

    return tablesWithFkToTable
      .map((joinTableName) => {
        const joinTable = updatedTableSchema[joinTableName];
        const compositeTables = joinTable.compositePk?.length ? joinTable.compositePk.map((pk) => pk.table) : [];
        if (!compositeTables?.length || !compositeTables?.includes(tableName)) {
          return null;
        }
        return {
          tableName: joinTableName,
          joinAttributes: joinTable.compositePk?.length ? joinTable.compositePk : undefined,
          fkColumns: joinTable.compositePk?.length
            ? joinTable.fk.filter((fk) => !joinTable.compositePk?.find((ct) => fk.attributeId === ct.attributeId))
            : joinTable.fk,
          tableType: joinTable.compositePk?.length ? LookupTypes.JOIN : LookupTypes.FOREIGN
        };
      })
      .filter(Boolean) as JoinTable[];
  }
  /*
  Retrieve primary key of specific table
  */
  _getPrimaryKey(table: TableSchema) {
    const pkRegex = /<pk\/>/;
    const primaryAttribute = Object.entries(table.properties).find((attr: any) => {
      const attributeVal = attr[1];
      if (!attributeVal.description) return false;
      const match = attributeVal.description.match(pkRegex);
      if (match) return true;
      else return false;
    });
    if (primaryAttribute) return primaryAttribute[0];
    else return "";
  }

  /*
  Retrieve primary key of specific table
  */
  _getCompositePrimaryKeys(table: TableSchema) {
    const pkRegex = /<pk\/>/;
    const primaryAttributes: CompositePKey[] = [];
    Object.entries(table.properties).forEach((attr: any) => {
      const attributeId = attr[0];
      const attributeVal = attr[1];
      if (attributeVal.description) {
        const match = attributeVal.description.match(pkRegex);
        if (match) {
          const fkRegex = /<fk table='(?<tableId>.*?)' column=/;
          const fkMatch = attributeVal.description.match(fkRegex);
          if (fkMatch) {
            primaryAttributes.push({
              attributeId,
              table: fkMatch.groups.tableId as string
            });
          } else {
            if (attributeId === "id") {
              primaryAttributes.push({
                attributeId,
                table: ""
              });
            }
          }
        }
      }
    });
    // Only id in primary key, return empty array as it's not a composite
    if (primaryAttributes.length === 1 && primaryAttributes.find((ck) => ck.attributeId === "id")) {
      return [];
    }
    return primaryAttributes;
  }

  /*
  Retrieve foreign keys and corresponding foreign tables
  */
  _getForeignKeys(table: TableSchema): FKey[] | [] {
    const outgoing = Object.entries(table.properties)
      .map((attr: any) => {
        const attributeId = attr[0];
        const attributeVal = attr[1];
        const fkRegex = /<fk table='(?<tableId>.*?)' column=/;
        if (!attributeVal.description) return null;
        const match = attributeVal.description.match(fkRegex);
        const fkColumnRegex = /fk\s+table=['"]\w+['"]\s+column=['"](\w+)['"]/;
        const columnMatch = attributeVal.description.match(fkColumnRegex);
        if (match) {
          const finalAttr: FKey = {
            attributeId,
            table: match.groups.tableId as string,
            fkAttributeId: ""
          };
          if (columnMatch) {
            finalAttr.fkAttributeId = columnMatch[1];
          }
          return finalAttr;
        } else {
          return null;
        }
      })
      .filter((v) => v);
    return outgoing as FKey[];
  }

  getForeignTable(tableId: string, foreignKey: string) {
    const column = this._schema[tableId].properties[foreignKey];
    if (!column.description) return null;
    const foreignTableRgx = /<fk table='(?<foreignTableId>.*?)' column='(?<foreignJoinId>.*?)'\/>/;
    const foreignTableMatch = column.description.match(foreignTableRgx);
    if (foreignTableMatch) {
      return {
        tableId: (foreignTableMatch.groups as any).foreignTableId,
        joinId: (foreignTableMatch.groups as any).foreignJoinId
      };
    } else {
      return null;
    }
  }

  getFkColumns(sourceTable: string, foreignTable: string) {
    // Might be more than one
    return this._getForeignKeys(this.extendedSchema[sourceTable])
      .filter((out: any) => out.table === foreignTable)
      .map((out: any) => out.attributeId);
  }

  getJoinTable(table1: string, table2: string) {
    const incoming1 = Object.keys(this._schema).filter((table) =>
      this._getForeignKeys(this.extendedSchema[table])
        .map((foreignTable: any) => foreignTable.table)
        .includes(table1)
    );
    const incoming2 = Object.keys(this._schema).filter((table) =>
      this._getForeignKeys(this.extendedSchema[table])
        .map((foreignTable: any) => foreignTable.table)
        .includes(table2)
    );
    return intersection(incoming1, incoming2)[0];
  }
}

export const schemaInstance = new Schema({});
