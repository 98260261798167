import { useCallback } from "react";

import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { InfoLoggerProps } from "types/common";

const isLocalDev = !process.env.NEXT_PUBLIC_VERCEL_URL;

const useInfoLogger = () => {
  const supabaseClient = useSupabaseBrowser();

  const logInfo = useCallback(
    async ({ source, additionalInfo }: InfoLoggerProps) => {
      if (isLocalDev) {
        return;
      }
      await supabaseClient.from("info_logs").insert({
        source,
        url: window.location.href,
        additional_info: additionalInfo
      });
    },
    [supabaseClient]
  );

  return {
    logInfo
  };
};

export default useInfoLogger;
