import { useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

import useErrorLogger from "hooks/useErrorLogger";
import { ERROR_TYPES, FORM_TRIGGER_TYPES, SPECIAL_DEFAULTS, ViewOption } from "utils/constants";

import { RecordItem, SelectOption } from "types/common";
import { AddInput } from "types/apiTypes";
import { TableColumnType } from "types/baTypes";

import useUIState from "./useUIState";
import useTableActionsState from "./useTableActionsState";
import useCurrentUser from "./useCurrentUser";
import useAddRecord from "./useAddRecord";
import useRemoveRecord from "./useRemoveRecord";
import useFilesProcessUpload from "./useFilesProcessUpload";
import useUpdateRecord from "./useUpdateRecord";
import useSchemaState from "./useSchemaState";
import useSearchQueryParams from "./useSearchQueryParams";

const useCreateFormRecord = ({
  processLookupTextFields,
  trigger
}: {
  processLookupTextFields: (
    record: RecordItem,
    errorLogAdditionalProps?: RecordItem
  ) => Promise<
    | RecordItem
    | { success: boolean; addInputToDelete?: Array<{ tableName: string; id: string }>; inputResponses?: RecordItem }
  >;
  trigger?: FORM_TRIGGER_TYPES;
}) => {
  const { showErrorToast } = useUIState();
  const { logError } = useErrorLogger();
  const { currentRecordId, currentProjectId } = useTableActionsState();
  const { currentSearchContextProjectId, currentSearchContextRecordId } = useSearchQueryParams();
  const currentUser = useCurrentUser();
  const { addRecordAsync, isLoading: isAddingRecord } = useAddRecord();
  const { removeRecordAsync, isLoading: isRemovingRecord } = useRemoveRecord();
  const { updateRecordAsync, isLoading: isUpdatingRecord } = useUpdateRecord();
  const { schemaInstance } = useSchemaState();

  const { uploadPercent, uploadFiles, uploadJoinTableFiles } = useFilesProcessUpload();

  const handleCreatedRecord = useCallback(
    async ({
      tableName,
      input,
      createdInProps,
      errorLogAdditionalProps
    }: {
      tableName: string;
      input: RecordItem;
      createdInProps?: { createdIn: string; createdInPath: string };
      errorLogAdditionalProps?: RecordItem;
    }) => {
      const finalRecordInput: RecordItem = { ...input };
      if (createdInProps?.createdIn) {
        finalRecordInput.created_in = createdInProps.createdIn;
        finalRecordInput.created_in_path = createdInProps.createdInPath;
      }
      // create record
      const createRecordResp = await addRecordAsync({ tableName: tableName, input: finalRecordInput });

      if (!createRecordResp.data?.length) {
        if (createRecordResp.error) {
          logError({
            error: createRecordResp.error,
            source: "useCreateFormRecord - handleCreatedRecord",
            type: ERROR_TYPES.HOOKS,
            message: createRecordResp.error.message || "Error adding record",
            url: window.location.href,
            additionalInfo: {
              tableName,
              finalRecordInput,
              ...errorLogAdditionalProps
            }
          });
        }
        if (createRecordResp?.error?.code === "23502") {
          const columnName = createRecordResp?.error?.message?.split('column "')[1].split('" of relation')[0];
          showErrorToast(
            `There was an error creating a record as a column marked as required in the database was missing.${
              columnName ? ' Missing column: "' + columnName + '"' : ""
            }'`
          );
          return;
        }
        showErrorToast(`There was an error creating the record. ${createRecordResp.error?.message}`);
        return;
      }
      return createRecordResp.data[0];
    },
    [addRecordAsync, showErrorToast, logError]
  );

  const rollBackCreatedRecords = useCallback(
    async (deleteInputs: Array<{ tableName: string; id: string }>) => {
      if (deleteInputs.length) {
        showErrorToast("Rolling back related table records entries", { autoClose: 5000 });
        await Promise.all(deleteInputs.map((input) => removeRecordAsync({ tableName: input.tableName, id: input.id })));
        showErrorToast("Rollback successful", { autoClose: 5000 });
      }
    },
    [removeRecordAsync, showErrorToast]
  );

  const onCreateBaseTableData = useCallback(
    async ({
      finalTableName,
      createdInProps,
      inputFields,
      formColumns,
      errorLogAdditionalProps
    }: {
      inputFields: RecordItem;
      finalTableName: string;
      createdInProps?: { createdIn: string; createdInPath: string };
      formColumns?: TableColumnType[];
      errorLogAdditionalProps?: RecordItem;
    }) => {
      // We need to remove the joinTableFileTagInput, json and filesListRemoveIds from the inputFields
      const { joinTableInput, joinTableFileTagInput, filesListRemoveIds, json, files, ...recordInput } = inputFields;

      if (isEmpty(recordInput)) {
        showErrorToast("No direct fields present for creating record. Please check all fields and try again.");
        // if no direct record field present cannot create main record
        logError({
          error: new Error("No direct fields present for creating record"),
          source: "useCreateFormRecord - onCreateBaseTableData",
          type: ERROR_TYPES.HOOKS,
          message: `Error creating record as no fields are present for table ${finalTableName}`,
          url: window.location.href,
          additionalInfo: {
            finalTableName,
            inputFields,
            createdInProps,
            ...errorLogAdditionalProps
          }
        });
        return;
      }

      // all direct table record input fields
      let finalRecordInput: RecordItem = recordInput;
      // Add check for any SPECIAL_DEFAULTS
      Object.keys(recordInput).forEach((key) => {
        const recordValue = recordInput[key];
        const column = formColumns?.find((col) => col.name === key);
        const columnIsRequired = column && column?.views?.[ViewOption.FORM]?.isRequired;
        if (recordValue === SPECIAL_DEFAULTS.CURRENT_RECORD_ID) {
          if (trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextRecordId) {
            finalRecordInput[key] = currentSearchContextRecordId;
          } else if (currentRecordId) {
            finalRecordInput[key] = currentRecordId;
          } else {
            if (columnIsRequired) {
              showErrorToast("Special default current record id not found.");
            } else {
              finalRecordInput[key] = null;
            }
          }
        }
        if (recordValue === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID) {
          if (trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId) {
            finalRecordInput[key] = currentSearchContextProjectId;
          } else if (currentProjectId) {
            finalRecordInput[key] = currentProjectId;
          } else {
            if (columnIsRequired) {
              showErrorToast("Special default current project id not found.");
            } else {
              finalRecordInput[key] = null;
            }
          }
        }
        if (recordValue === SPECIAL_DEFAULTS.CURRENT_USER_ID) {
          if (currentUser?.id) {
            finalRecordInput[key] = currentUser.id;
          } else {
            if (columnIsRequired) {
              showErrorToast("Special default current user id not found.");
            } else {
              finalRecordInput[key] = null;
            }
          }
        }
      });

      // has foreignKey Field for text type
      const lookupTextFieldInput = Object.keys(recordInput).reduce((acc: RecordItem, curr) => {
        if (isObject(recordInput[curr])) {
          acc[curr] = recordInput[curr];
        }
        return acc;
      }, {});

      if (!isEmpty(lookupTextFieldInput)) {
        const lookupTextFieldResp = await processLookupTextFields(lookupTextFieldInput, errorLogAdditionalProps);
        if (lookupTextFieldResp.success) {
          finalRecordInput = {
            ...finalRecordInput,
            ...lookupTextFieldResp.inputResponses
          };
        } else {
          showErrorToast("There was an error creating the record. Please check all fields and try again.");
          const inputsToDelete = lookupTextFieldResp.addInputToDelete;
          if (inputsToDelete?.length) {
            await rollBackCreatedRecords(inputsToDelete);
          }
          return;
        }
      }

      const finalInputToDelete: Array<{ id: string; tableName: string }> = [];
      const newRecord = await handleCreatedRecord({
        tableName: finalTableName,
        input: finalRecordInput,
        createdInProps,
        errorLogAdditionalProps
      });
      if (!newRecord?.id) {
        showErrorToast("Record creation failed, please check all fieldsand try again");
        return;
      }
      finalInputToDelete.push({ id: newRecord?.id, tableName: finalTableName });
      // upload ForeignKey Files
      if (!isEmpty(files)) {
        const tableProps = schemaInstance?.extendedSchema?.[finalTableName];
        const uploadedFilesResp = await uploadFiles(files, `${finalTableName}/${newRecord.id}`, createdInProps);
        if (uploadedFilesResp) {
          if (
            tableProps?.attributeIds?.includes("files_list_id") ||
            tableProps?.attributeIds?.includes("files_lists_id")
          ) {
            const firstKey = Object.keys(uploadedFilesResp)?.[0];
            const fileIds = uploadedFilesResp[firstKey];

            const newListResp = await addRecordAsync({
              tableName: "files_lists",
              input: {
                created_in: createdInProps?.createdIn,
                created_in_path: createdInProps?.createdInPath,
                project_id:
                  trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                    ? currentSearchContextProjectId
                    : currentProjectId
              }
            });
            if (newListResp?.data?.[0]?.id) {
              const filesListId = newListResp?.data?.[0]?.id;
              await updateRecordAsync({
                tableName: finalTableName,
                input: { id: newRecord.id, files_list_id: filesListId }
              });
              if (fileIds?.length) {
                const fileJoinInputs = fileIds.map((fileId: string) => {
                  return {
                    files_lists_id: filesListId,
                    files_id: fileId,
                    project_id:
                      trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                        ? currentSearchContextProjectId
                        : currentProjectId
                  };
                });
                await Promise.all(
                  fileJoinInputs.map((input: RecordItem) => addRecordAsync({ tableName: "files_lists_files", input }))
                );
              }
            }
          } else {
            const updateRes = await updateRecordAsync({
              tableName: finalTableName,
              input: { id: newRecord.id, ...uploadedFilesResp }
            });
            if (!updateRes.data?.length) {
              if (updateRes.error) {
                logError({
                  error: updateRes.error,
                  source: "useCreateFormRecord - onCreateBaseTableData",
                  type: ERROR_TYPES.HOOKS,
                  message: updateRes.error.message || "There was an error adding additional record file information",
                  url: window.location.href,
                  additionalInfo: {
                    finalTableName,
                    finalRecordInput,
                    uploadedFilesResp,
                    ...errorLogAdditionalProps
                  }
                });
              }
              showErrorToast("There was an error adding additional record file information.");
              return;
            }
          }
        } else {
          if (
            (tableProps?.attributeIds?.includes("files_list_id") ||
              tableProps?.attributeIds?.includes("files_lists_id")) &&
            Object.keys(files)?.includes("files_list_id")
          ) {
            // Check if files options are coming from Add Many
            const fileIds: string[] = [];
            const fileArrays = files["files_list_id"];
            fileArrays?.forEach((fileOption: SelectOption) => {
              // Is a file option
              if (fileOption?.optionData?.id && fileOption?.optionData?.path) {
                fileIds.push(fileOption?.optionData?.id);
              }
            });
            if (fileIds.length) {
              const newListResp = await addRecordAsync({
                tableName: "files_lists",
                input: {
                  created_in: createdInProps?.createdIn,
                  created_in_path: createdInProps?.createdInPath,
                  project_id:
                    trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                      ? currentSearchContextProjectId
                      : currentProjectId
                }
              });
              if (newListResp?.data?.[0]?.id) {
                const finalFilesListId = newListResp?.data?.[0]?.id;
                await updateRecordAsync({
                  tableName: finalTableName,
                  input: { id: newRecord.id, files_list_id: finalFilesListId }
                });
                // Add files to files list
                const fileJoinInputs = fileIds.map((fileId: string) => {
                  return {
                    files_lists_id: finalFilesListId,
                    files_id: fileId,
                    project_id:
                      trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                        ? currentSearchContextProjectId
                        : currentProjectId
                  };
                });
                await Promise.all(
                  fileJoinInputs.map((input: RecordItem) => addRecordAsync({ tableName: "files_lists_files", input }))
                );
              }
            }
          }
        }
      }

      // TODO: Find cases where this condition is fulfilled outside of attached files
      // Most tested use cases are for attached files
      if (!isEmpty(joinTableInput)) {
        const { files: joinTableFilesInput, joinTableLookupNameToTableMap, ...joinTableInputFields } = joinTableInput;
        const joinTableAddQueriesInput: AddInput[] = [];

        // upload join table files
        if (joinTableFilesInput) {
          const uploadedFilesInput = await uploadJoinTableFiles(
            joinTableFilesInput,
            `${finalTableName}/${newRecord.id}`,
            joinTableLookupNameToTableMap,
            true,
            createdInProps
          );
          if (uploadedFilesInput) {
            uploadedFilesInput.forEach((fileInput) => {
              const recordKey = Object.keys(fileInput.input).find(
                (key) => fileInput.input[key] === undefined && key !== "id"
              );
              if (recordKey) {
                fileInput.input[recordKey] = newRecord.id;
              }
              joinTableAddQueriesInput.push(fileInput);
            });
          }
        }
        Object.keys(joinTableInputFields).forEach((joinTable) => {
          const finalLookupTableName = joinTableLookupNameToTableMap[joinTable];
          const joinInputFields = Array.isArray(joinTableInput[joinTable])
            ? [...joinTableInput[joinTable]]
            : [{ ...joinTableInput[joinTable] }];

          const joinQueriesInput = joinInputFields.map((input) => {
            const recordKey = Object.keys(input).find((key) => input[key] === undefined && key !== "id");
            if (recordKey) {
              input[recordKey] = newRecord.id;
            }
            if (createdInProps?.createdIn) {
              input.created_in = createdInProps.createdIn;
              input.created_in_path = createdInProps.createdInPath;
            }
            return { tableName: finalLookupTableName, input };
          });
          joinTableAddQueriesInput.push(...joinQueriesInput);
        });

        if (joinTableAddQueriesInput.length) {
          const joinQueriesResponse = await Promise.all(joinTableAddQueriesInput.map((input) => addRecordAsync(input)));
          const errorResponses: any = [];
          const successResponses: Array<{ tableName: string; id: string }> = [];
          joinQueriesResponse.forEach((queryResponse, index) => {
            if (queryResponse.error) {
              errorResponses.push(queryResponse);
            } else {
              const joinInputEntry = joinTableAddQueriesInput[index];
              successResponses.push({
                tableName: joinInputEntry.tableName,
                id: queryResponse.data[0].id
              });
            }
          });
          if (errorResponses.length) {
            logError({
              error: new Error("Error one of the join table relation creation for record failed"),
              source: "useCreateFormRecord - onCreateBaseTableData",
              type: ERROR_TYPES.HOOKS,
              message: "There was an error creating the linked records.",
              url: window.location.href,
              additionalInfo: {
                finalTableName,
                joinTableAddQueriesInput,
                errorResponses,
                ...errorLogAdditionalProps
              }
            });
            showErrorToast("There was an error creating the linked records. Please check all fields and try again.");
            // Delete any successfully created records
            successResponses.forEach((successResponse) => {
              finalInputToDelete.push(successResponse);
            });
            await rollBackCreatedRecords(finalInputToDelete);
            return;
          }
        }
      }

      return newRecord;
    },
    [
      addRecordAsync,
      currentProjectId,
      currentRecordId,
      currentUser?.id,
      handleCreatedRecord,
      rollBackCreatedRecords,
      showErrorToast,
      uploadFiles,
      uploadJoinTableFiles,
      updateRecordAsync,
      processLookupTextFields,
      schemaInstance?.extendedSchema,
      logError,
      trigger,
      currentSearchContextProjectId,
      currentSearchContextRecordId
    ]
  );

  const onCreateJoinTableData = useCallback(
    async ({
      inputFields,
      finalTableName,
      createdInProps,
      formColumns,
      errorLogAdditionalProps
    }: {
      inputFields: RecordItem;
      finalTableName: string;
      createdInProps?: { createdIn: string; createdInPath: string };
      formColumns?: TableColumnType[];
      errorLogAdditionalProps?: RecordItem;
    }) => {
      const recordCompositePk = schemaInstance?.extendedSchema[finalTableName].compositePk;
      const { joinTableInput, multiCreate, ...recordInput } = inputFields;
      const finalInput = {
        ...recordInput
      };
      const finalInputToDelete: Array<{ tableName: string; id: string }> = [];
      const finalRecordCompositeKey = recordCompositePk?.filter((key) => key.attributeId !== "id"); // remove id from composite key

      // Create new entries for join table composite key and then use ids
      if (!isEmpty(joinTableInput)) {
        const joinTableInputFields = Object.keys(joinTableInput);
        const joinTableQueriesInput: AddInput[] = [];
        joinTableInputFields.forEach((field) => {
          const key = recordCompositePk?.find((pk) => pk.attributeId === field);
          if (key) {
            joinTableQueriesInput.push({
              tableName: key.table,
              input: joinTableInput[field]
            });
          }
        });

        if (joinTableQueriesInput.length) {
          const JoinTableQueriesResponse = await Promise.all(
            joinTableQueriesInput.map((input) =>
              onCreateBaseTableData({
                finalTableName: input.tableName,
                inputFields: input.input,
                createdInProps,
                formColumns,
                errorLogAdditionalProps
              })
            )
          );
          const errorResponses: Array<RecordItem> = [];
          JoinTableQueriesResponse.forEach((queryResponse, index) => {
            const inputEntry = joinTableQueriesInput[index];
            if (!queryResponse) {
              errorResponses.push(inputEntry);
            } else {
              finalInputToDelete.push({ tableName: inputEntry.tableName, id: queryResponse.id });
            }
          });
          if (errorResponses.length) {
            logError({
              error: new Error("Error one of the join table relation creation for record failed"),
              source: "useCreateFormRecord - onCreateJoinTableData",
              type: ERROR_TYPES.HOOKS,
              message: "There was an error creating related table records",
              url: window.location.href,
              additionalInfo: {
                finalTableName,
                joinTableQueriesInput,
                errorResponses,
                ...errorLogAdditionalProps
              }
            });
            showErrorToast("There was an error creating related table records. ");
            await rollBackCreatedRecords(finalInputToDelete);
            return;
          }

          joinTableInputFields.forEach((field, index) => {
            finalInput[field] = JoinTableQueriesResponse[index].id;
          });
        }
      }
      const multiCreateInput: RecordItem[] = [];
      // TODO: Move multi create handling to conditions of the table
      // instead of relying on manual selection
      if (multiCreate) {
        const multiCreateInputFields = recordInput[multiCreate];

        if (multiCreateInputFields?.length) {
          // Assumes there can be max 3 columns in composite keys
          const otherCompositeKey = recordCompositePk?.find(
            (key) => key.attributeId !== multiCreate && key.attributeId !== "id"
          );
          multiCreateInputFields.forEach((input: RecordItem) => {
            if ((input.optionData?.id || input?.record?.id) && otherCompositeKey?.attributeId) {
              multiCreateInput.push({
                [multiCreate]: input.optionData?.id || input?.record?.id,
                [otherCompositeKey.attributeId]: recordInput[otherCompositeKey?.attributeId]
              });
            }
          });
        }

        delete finalInput[multiCreate];
        // Create the multiple records
        const multiCreateResponse = await Promise.all(
          multiCreateInput.map((input) => addRecordAsync({ tableName: finalTableName, input }))
        );

        const errorResponses: Array<RecordItem> = [];
        multiCreateResponse.forEach((queryResponse) => {
          if (queryResponse.error) {
            errorResponses.push(queryResponse);
          } else {
            finalInputToDelete.push({ tableName: finalTableName, id: queryResponse.data?.[0]?.id });
          }
        });
        if (errorResponses.length) {
          logError({
            error: new Error("Error one of the multi create for record failed"),
            source: "useCreateFormRecord - onCreateJoinTableData",
            type: ERROR_TYPES.HOOKS,
            message: "There was an error creating a multi create record",
            url: window.location.href,
            additionalInfo: {
              finalTableName,
              multiCreateInput,
              errorResponses,
              recordInput,
              inputFields,
              ...errorLogAdditionalProps
            }
          });
          showErrorToast("There was an error creating multiple records. ");
          await rollBackCreatedRecords(finalInputToDelete);
          return;
        }
        // Return as no other update will be handled
        return multiCreateResponse?.[0]?.data?.[0];
      }

      //check if all composite key fields are present
      const isCompositeKeyPresent = finalRecordCompositeKey?.every((key) => !!finalInput[key.attributeId]);
      if (!isCompositeKeyPresent) {
        console.log("@Error one of the composite key field missing");
        logError({
          error: new Error("Error one of the multi create for record failed"),
          source: "useCreateFormRecord - isCompositeKeyPresent",
          type: ERROR_TYPES.HOOKS,
          message: "A required composite key was not found",
          url: window.location.href,
          additionalInfo: {
            finalTableName,
            multiCreateInput,
            finalRecordCompositeKey,
            recordInput,
            inputFields,
            finalInput,
            ...errorLogAdditionalProps
          }
        });
        showErrorToast(
          "There was an error finding the required data to create the record, please check form configuration and try again. "
        );
        return;
      }
      const newRecord = await handleCreatedRecord({
        tableName: finalTableName,
        input: finalInput,
        createdInProps,
        errorLogAdditionalProps
      });
      return newRecord;
    },
    [
      addRecordAsync,
      handleCreatedRecord,
      rollBackCreatedRecords,
      onCreateBaseTableData,
      schemaInstance?.extendedSchema,
      showErrorToast,
      logError
    ]
  );

  return {
    onCreateBaseTableData,
    isAddingRecord,
    uploadPercent,
    isRemovingRecord,
    isUpdatingRecord,
    onCreateJoinTableData
  };
};

export default useCreateFormRecord;
