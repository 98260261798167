import { singular } from "utils/pluralize";

export const truncateText = (text: string, max: number) => {
  if (text.length > max) {
    return text.substring(0, max - 3) + "...";
  }

  return text;
};

export const copyText = (value: string) => {
  return navigator.clipboard.writeText(value);
};

// Basic Html to Text converter
// Source:  https://github.com/EDMdesigner/textversionjs/blob/master/src/textversion.js
const populateChar = (ch: string, amount: number) => {
  let result = "";
  for (let i = 0; i < amount; i += 1) {
    result += ch;
  }
  return result;
};

// @ts-ignore
export const htmlToPlainText = (
  htmlText: string,
  options: {
    keepNbsps?: boolean;
  } = {}
) => {
  const linkProcess = null;
  const imgProcess = null;
  const headingStyle = "underline"; // hashify, breakline
  const listStyle = "indention"; // indention, linebreak
  const uIndentionChar = "-";
  const listIndentionTabs = 3;
  const oIndentionChar = "-";
  const keepNbsps = options.keepNbsps || false;

  const uIndention = populateChar(uIndentionChar, listIndentionTabs);

  // removel all \n linebreaks
  let tmp = String(htmlText).replace(/\n|\r/g, " ");

  // remove everything before and after <body> tags including the tag itself
  const bodyEndMatch = tmp.match(/<\/body>/i);
  if (bodyEndMatch) {
    tmp = tmp.substring(0, bodyEndMatch.index);
  }
  const bodyStartMatch = tmp.match(/<body[^>]*>/i);
  if (bodyStartMatch) {
    tmp = tmp.substring((bodyStartMatch?.index as number) + bodyStartMatch[0].length, tmp.length);
  }

  // remove inbody scripts and styles
  tmp = tmp.replace(/<(script|style)( [^>]*)*>((?!<\/\1( [^>]*)*>).)*<\/\1>/gi, "");

  // remove all tags except that are being handled separately
  tmp = tmp.replace(
    /<(\/)?((?!h[1-6]( [^>]*)*>)(?!img( [^>]*)*>)(?!a( [^>]*)*>)(?!ul( [^>]*)*>)(?!ol( [^>]*)*>)(?!li( [^>]*)*>)(?!p( [^>]*)*>)(?!div( [^>]*)*>)(?!td( [^>]*)*>)(?!br( [^>]*)*>)[^>\/])[^<>]*>/gi,
    ""
  );

  // remove or replace images - replacement texts with <> tags will be removed also, if not intentional, try to use other notation
  tmp = tmp.replace(/<img([^>]*)>/gi, function (str, imAttrs) {
    let imSrc = "";
    let imAlt = "";
    const imSrcResult = /src="([^"]*)"/i.exec(imAttrs);
    const imAltResult = /alt="([^"]*)"/i.exec(imAttrs);
    if (imSrcResult !== null) {
      imSrc = imSrcResult[1];
    }
    if (imAltResult !== null) {
      imAlt = imAltResult[1];
    }
    if (typeof imgProcess === "function") {
      // @ts-ignore
      return imgProcess(imSrc, imAlt);
    }
    if (imAlt === "") {
      return "![image] (" + imSrc + ")";
    }
    return "![" + imAlt + "] (" + imSrc + ")";
  });

  function createListReplaceCb() {
    // @ts-ignore
    return function (match, listType, listAttributes, listBody) {
      let liIndex = 0;
      if (listAttributes && /start="([0-9]+)"/i.test(listAttributes)) {
        // @ts-ignore
        liIndex = /start="([0-9]+)"/i.exec(listAttributes)[1] - 1;
      }
      const plainListItem =
        "<p>" +
        listBody.replace(/<li[^>]*>(((?!<li[^>]*>)(?!<\/li>).)*)<\/li>/gi, function (str: string, listItem: string) {
          let actSubIndex = 0;
          const plainListLine = listItem.replace(/(^|(<br \/>))(?!<p>)/gi, function () {
            if (listType === "o" && actSubIndex === 0) {
              liIndex += 1;
              actSubIndex += 1;
              return "<br />" + liIndex + populateChar(oIndentionChar, listIndentionTabs - String(liIndex).length);
            }
            return "<br />" + uIndention;
          });
          return plainListLine;
        }) +
        "</p>";
      return plainListItem;
    };
  }

  // handle lists
  // @ts-ignore
  if (listStyle === "linebreak") {
    tmp = tmp.replace(/<\/?ul[^>]*>|<\/?ol[^>]*>|<\/?li[^>]*>/gi, "\n");
  } else if (listStyle === "indention") {
    while (/<(o|u)l[^>]*>(.*)<\/\1l>/gi.test(tmp)) {
      tmp = tmp.replace(/<(o|u)l([^>]*)>(((?!<(o|u)l[^>]*>)(?!<\/(o|u)l>).)*)<\/\1l>/gi, createListReplaceCb());
    }
  }

  // handle headings
  // @ts-ignore
  if (headingStyle === "linebreak") {
    tmp = tmp.replace(/<h([1-6])[^>]*>([^<]*)<\/h\1>/gi, "\n$2\n");
  } else if (headingStyle === "underline") {
    tmp = tmp.replace(/<h1[^>]*>(((?!<\/h1>).)*)<\/h1>/gi, function (str, p1) {
      return "\n&nbsp;\n" + p1 + "\n" + populateChar("=", p1.length) + "\n&nbsp;\n";
    });
    tmp = tmp.replace(/<h2[^>]*>(((?!<\/h2>).)*)<\/h2>/gi, function (str, p1) {
      return "\n&nbsp;\n" + p1 + "\n" + populateChar("-", p1.length) + "\n&nbsp;\n";
    });
    tmp = tmp.replace(/<h([3-6])[^>]*>(((?!<\/h\1>).)*)<\/h\1>/gi, function (str, p1, p2) {
      return "\n&nbsp;\n" + p2 + "\n&nbsp;\n";
    });
  } else if (headingStyle === "hashify") {
    tmp = tmp.replace(/<h([1-6])[^>]*>([^<]*)<\/h\1>/gi, function (str, p1, p2) {
      return "\n&nbsp;\n" + populateChar("#", p1) + " " + p2 + "\n&nbsp;\n";
    });
  }

  // replace <br>s, <td>s, <divs> and <p>s with linebreaks
  tmp = tmp.replace(
    /<br( [^>]*)*>|<p( [^>]*)*>|<\/p( [^>]*)*>|<div( [^>]*)*>|<\/div( [^>]*)*>|<td( [^>]*)*>|<\/td( [^>]*)*>/gi,
    "\n"
  );

  // replace <a href>b<a> links with b (href) or as described in the linkProcess function
  tmp = tmp.replace(/<a[^>]*href="([^"]*)"[^>]*>([^<]+)<\/a[^>]*>/gi, function (str, href, linkText) {
    if (typeof linkProcess === "function") {
      // @ts-ignore
      return linkProcess(href, linkText);
    }
    return " [" + linkText + "] (" + href + ") ";
  });

  // remove whitespace from empty lines excluding nbsp
  tmp = tmp.replace(/\n[ \t\f]*/gi, "\n");

  // remove duplicated empty lines
  tmp = tmp.replace(/\n\n+/gi, "\n");

  if (keepNbsps) {
    // remove duplicated spaces including non braking spaces
    tmp = tmp.replace(/( |\t)+/gi, " ");
    tmp = tmp.replace(/&nbsp;/gi, " ");
  } else {
    // remove duplicated spaces including non braking spaces
    tmp = tmp.replace(/( |&nbsp;|\t)+/gi, " ");
  }

  // remove line starter spaces
  tmp = tmp.replace(/\n +/gi, "\n");

  // remove content starter spaces
  tmp = tmp.replace(/^ +/gi, "");

  // remove first empty line
  while (tmp.indexOf("\n") === 0) {
    tmp = tmp.substring(1);
  }

  // put a new line at the end
  if (tmp.length === 0 || tmp.lastIndexOf("\n") !== tmp.length - 1) {
    tmp += "\n";
  }

  return tmp;
};

export const isHTMLStringEmpty = (htmlString: string) => {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  const content = div.innerText.trim();

  if (content === "") {
    return true;
  }
  return false;
};

export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isJSONString = (str: string) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const isUpperCase = (string: string) => /^[A-Z]*$/.test(string);

export const generatePageTitle = (title?: string) => {
  if (!title) return "";

  if (isUpperCase(title)) {
    return title;
  }

  return singular(title);
};

// Lexical Editor usually adds some extra content to the end of the editor
// or directionality attributes to the editor. This function removes them
const cleanLexicalContent = (content: string) => {
  const dirLtr = ' dir="ltr"';
  const newLineAtFinal = '<p class="PlaygroundEditorTheme__paragraph"><br></p>';
  const newListAtFinal =
    '<ul class="PlaygroundEditorTheme__ul1"><li value="1" class="PlaygroundEditorTheme__listItem"></li></ul>';

  const finalContent = content.replaceAll(dirLtr, "");

  if (finalContent.endsWith(newLineAtFinal) || finalContent.startsWith(newLineAtFinal)) {
    return finalContent.replaceAll(newLineAtFinal, "");
  }

  if (finalContent.endsWith(newListAtFinal)) {
    return finalContent.replace(newListAtFinal, "");
  }

  return finalContent;
};

export const isEqualLexicalContent = (a: string, b: string) => {
  const aContent = cleanLexicalContent(a);
  const bContent = cleanLexicalContent(b);

  return aContent === bContent;
};

export const getTextWidth = (text: string) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) return 0;

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
};
