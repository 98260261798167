import dayjs from "dayjs";
import fileDownloadLib from "js-file-download";
import JSZip from "jszip";
import isObject from "lodash/isObject";
import noop from "lodash/noop";
import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";

import { isBasicColumn } from "components/Admin/utils";
import { buildSupabasePublicUrl } from "components/Image/utils";
import { readLookupFields } from "lib/utils";
import { DefaultValue, Page, TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { ApiRecordType } from "types/apiTypes";
import { CellType, FileType, LookupTypes, SPECIAL_DEFAULTS, SPECIAL_DEFAULTS_VALUES, ViewOption } from "./constants";
import { copyText } from "./texts";
import { ExtendedSchema } from "./schema";
import { appendRecordTypeLookupToCol } from "./columnUtils";

export const getDerivedFileType = (fileType: string) =>
  isImageFile(fileType)
    ? "Image"
    : isVideoFile(fileType)
      ? "Video"
      : isDrawingFile(fileType)
        ? "Drawing"
        : isDocumentFile(fileType)
          ? "Document"
          : isAudioFile(fileType)
            ? "Audio"
            : "Others";

export const isImageFile = (fileType: string) => fileType && fileType.toLowerCase().startsWith("image");

export const isVideoFile = (fileType: string) => fileType && fileType.toLowerCase().startsWith("video");

export const isDocumentFile = (fileType: string) =>
  fileType &&
  (fileType.toLowerCase().includes("pdf") ||
    fileType.toLowerCase().includes("doc") ||
    fileType.toLowerCase().includes("csv") ||
    fileType.toLowerCase().includes("xls") ||
    fileType.toLowerCase().includes("ppt"));

export const isDrawingFile = (fileType: string) => fileType && fileType.toLowerCase().includes("dwg");

export const isAudioFile = (fileType: string) => fileType && fileType.toLowerCase().startsWith("audio");

export const ACCEPTED_FILE_TYPES: Record<string, string[]> = {
  "image/*": [".jpeg", ".png", ".dwg", ".heic", ".webp", ".dwg"],
  "application/pdf": [],
  "video/*": [],
  "audio/*": [],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
  "application/msword": [],
  "application/vnd.ms-excel": [],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
  "application/vnd.ms-powerpoint": [],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
  "text/csv": []
};

export const processFilesForUpload = (
  finalFiles: File[] = [],
  triggeredBy?: string,
  defaultValues: RecordItem = {},
  additionalValues: RecordItem = {}
) => {
  const finalFilesList = finalFiles.map((file) => {
    return {
      file: file,
      path: `${triggeredBy}/${uuidv4()}`,
      file_type: getDerivedFileType(file.type),
      name: file.name,
      ...defaultValues,
      ...additionalValues
    };
  });
  return finalFilesList;
};

export const getFeaturedImageDetailsFromPageData = ({
  pageData,
  recordData,
  columns,
  recordTypesData,
  extendedSchema
}: {
  pageData?: Page;
  recordData?: RecordItem;
  columns?: TableColumnType[];
  recordTypesData?: ApiRecordType[];
  extendedSchema?: ExtendedSchema;
}) => {
  if (!pageData || !recordData) return null;

  let featuredImageColumn = columns?.find((col) => col.views?.[ViewOption.DETAIL_MAIN]?.isFeaturedImage);
  if (
    featuredImageColumn &&
    featuredImageColumn?.type === CellType.FILE &&
    Object.keys(featuredImageColumn?.lookupPath || {}).length === 1
  ) {
    featuredImageColumn =
      appendRecordTypeLookupToCol(featuredImageColumn, recordTypesData || [], pageData?.table_name, extendedSchema) ??
      undefined;
  }
  const fileValue = featuredImageColumn
    ? featuredImageColumn.isLookup
      ? readLookupFields(featuredImageColumn, recordData, extendedSchema, recordTypesData)
      : recordData[featuredImageColumn.name]
    : null;

  return getFeatureImageDetails(fileValue, recordData, false);
};

export const getFeatureImageDetails = (value: any, record: RecordItem, allowVideo = false, isRecordType?: boolean) => {
  if (!value) return null;
  const finalDetails: { value?: string; id?: string; type?: string; mimetype?: RecordItem; dimensions?: string } = {};
  if (Array.isArray(value)) {
    const featuredImg = value.find((val) => val?.is_featured);
    if (featuredImg) {
      finalDetails.value = featuredImg.path;
      finalDetails.type = featuredImg.file_type;
      finalDetails.id = isRecordType && featuredImg.files_id?.id ? featuredImg.files_id?.id : featuredImg.id;
      finalDetails.mimetype = featuredImg.mimetype;
      finalDetails.dimensions =
        featuredImg.width && featuredImg.height ? `${featuredImg.width} X ${featuredImg.height}` : undefined;
    } else {
      const val = value?.filter((file) => file?.file_type === FileType.IMAGE || allowVideo);
      finalDetails.value = val[0]?.path;
      finalDetails.type = val[0]?.file_type;
      finalDetails.id = isRecordType && val[0]?.files_id?.id ? val[0].files_id?.id : val[0]?.id;
      finalDetails.mimetype = val[0]?.mimetype;
      finalDetails.dimensions = val[0]?.width && val[0]?.height ? `${val[0].width} X ${val[0].height}` : undefined;
    }
  } else if (typeof value === "string") {
    finalDetails.value = value;
    finalDetails.type = record?.file_type;
    finalDetails.id = record?.id;
    finalDetails.mimetype = record?.mimetype;
    finalDetails.dimensions = record?.width && record?.height ? `${record.width} X ${record.height}` : undefined;
  }

  if ((value?.file_type === FileType.IMAGE || allowVideo) && typeof value !== "string" && !Array.isArray(value)) {
    finalDetails.value = value?.path;
    finalDetails.type = value?.file_type;
    finalDetails.id = value?.id;
    if (!finalDetails.mimetype) {
      finalDetails.mimetype = value?.mimetype;
    }

    if (!finalDetails.dimensions) {
      finalDetails.dimensions = value?.width && value?.height ? `${value.width} X ${value.height}` : undefined;
    }
  }

  if (!finalDetails?.type) {
    finalDetails.type = value?.file_type;
    finalDetails.id = value?.id;
  }

  return finalDetails;
};

export const getFeatureImageValue = (value: any, allowVideo = false) => {
  if (!value) return null;
  if (Array.isArray(value)) {
    const featuredImg = value.find((val) => val?.is_featured);
    return featuredImg
      ? featuredImg.path
      : value?.filter((file) => file?.file_type === FileType.IMAGE || allowVideo)[0]?.path;
  }
  if (typeof value === "string") {
    return value;
  }
  if (value?.file_type === FileType.IMAGE || allowVideo) {
    return value?.path;
  }
  return null;
};

export const getFeatureImageType = (value: any, record: RecordItem, allowVideo = false) => {
  if (!value) return null;
  if (Array.isArray(value)) {
    const featuredImg = value.find((val) => val?.is_featured);
    return featuredImg
      ? featuredImg.file_type
      : value?.filter((file) => file?.file_type === FileType.IMAGE || allowVideo)[0]?.file_type;
  }
  if (typeof value === "string") {
    return record?.file_type;
  }
  return value?.file_type;
};

export const getFeaturedImageMimeType = (value: any, record: RecordItem, allowVideo = false) => {
  if (!value) return null;
  if (Array.isArray(value)) {
    const featuredImg = value.find((val) => val?.is_featured);
    return featuredImg
      ? featuredImg.mimetype
      : value?.filter((file) => file?.file_type === FileType.IMAGE || allowVideo)[0]?.mimetype;
  }
  if (typeof value === "string") {
    return record?.mimetype;
  }
  return value?.mimetype;
};

export const downloadFileFromSupabase = async (path: string, name: string) => {
  const fileUrl = buildSupabasePublicUrl(path);
  downloadFile(fileUrl, name);
};

export const downloadFile = async (path: string, name: string) => {
  fetch(path)
    .then((res) => res.blob())
    .then((blob) => {
      fileDownloadLib(blob, name);
    });
};

export const downloadFiles = async ({
  files = [],
  onSuccess = noop,
  onError = noop
}: {
  files: { path: string; name: string }[];
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const today = dayjs().format("YYYY-MM-DD");
  const folderName = `BuildAppeal_${today}_${files.length}`;
  const filename = `${folderName}.zip`;
  const zip = new JSZip();
  const folder = zip.folder(folderName);

  const urls = files.map((file) => {
    return buildSupabasePublicUrl(file?.path);
  });

  const fileNamesAddedToFolder: string[] = [];
  urls.forEach((url) => {
    const blob = fetch(url).then(function (response) {
      if (response.status === 200) {
        return Promise.resolve(response.blob());
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    });
    let name = files.find((file) => url.includes(file.path))?.name || url.substring(url.lastIndexOf("/"));

    if (name && blob && folder) {
      // if file  arleady added to folder with same name
      if (fileNamesAddedToFolder.includes(name)) {
        fileNamesAddedToFolder.push(name);
        // split name from extension
        const [finalName, fileExtension] = name.split(".");
        const fileWithSameNameCount = fileNamesAddedToFolder.filter((addedFileName) => addedFileName === name).length;
        name = `${finalName}_${fileWithSameNameCount}.${fileExtension}`;
      } else {
        fileNamesAddedToFolder.push(name);
      }
      folder.file(name, blob);
    }
  });

  zip
    .generateAsync({ type: "blob" })
    .then((blob) => fileDownloadLib(blob, filename))
    .then(onSuccess)
    .catch(onError);
};

export const getFileJoinLookupTableDefaultValues = ({
  allPages,
  pageId,
  additionalValues,
  extendedSchema
}: {
  allPages: Page[];
  pageId: string;
  additionalValues: { currentProjectId?: string; currentRecordId?: string; currentUserId?: string };
  extendedSchema?: ExtendedSchema;
}) => {
  const page = allPages.find((page) => page.id === pageId);
  if (!page) {
    return null;
  }
  const defaultValues: RecordItem = {};
  page.columns?.forEach((col: TableColumnType) => {
    if (col.isLookup && col.lookupPath?.[0]?.lookupType === LookupTypes.JOIN) {
      const lookupJoinTable = col.lookupPath?.[0]?.lookupTableName;
      const tableProps = extendedSchema?.[lookupJoinTable];
      // Must be join table to files
      if (
        tableProps?.compositePk?.length &&
        tableProps?.compositePk?.find((pk) => pk.table === "files") &&
        !isEmpty(col.defaultValues)
      ) {
        defaultValues[lookupJoinTable] = {};
        tableProps.compositePk.forEach((pk) => {
          let finalValue = (col.defaultValues as DefaultValue)?.[pk.attributeId]?.value;
          if (finalValue && pk.table !== "files") {
            if (finalValue === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID && additionalValues.currentProjectId) {
              finalValue = additionalValues.currentProjectId;
            }
            if (finalValue === SPECIAL_DEFAULTS.CURRENT_RECORD_ID && additionalValues.currentRecordId) {
              finalValue = additionalValues.currentRecordId;
            }
            if (finalValue === SPECIAL_DEFAULTS.CURRENT_USER_ID && additionalValues.currentUserId) {
              finalValue = additionalValues.currentUserId;
            }
            defaultValues[lookupJoinTable][pk.attributeId] = finalValue;
          }
        });
      }
    }
  });
  return defaultValues;
};

export const getFileDefaultValues = (
  allPages: Page[],
  pageId: string,
  isJoinTable?: boolean,
  additionalValues: { currentProjectId?: string; currentRecordId?: string; currentUserId?: string } = {}
) => {
  const page = allPages.find((page) => page.id === pageId);

  const defaultValues: RecordItem = {};
  if (page) {
    page.columns?.forEach((col) => {
      if (!isJoinTable && isBasicColumn(col)) {
        if (col.defaultValues?.value) {
          defaultValues[col.name] = col.defaultValues.value;
        }
      }
      // Lookup columns from foreign keys on base table are supported here
      if (
        col.isLookup &&
        col.lookupPath?.["0"]?.lookupForeignKey &&
        (col.defaultValues as DefaultValue)?.[col.lookupPath?.["0"]?.lookupForeignKey]?.value
      ) {
        defaultValues[col.lookupPath?.["0"]?.lookupForeignKey] = (col.defaultValues as DefaultValue)?.[
          col.lookupPath?.["0"]?.lookupForeignKey
        ]?.value;
      }

      if (isJoinTable && !isBasicColumn(col)) {
        if (isObject(col.defaultValues)) {
          Object.keys(col.defaultValues || {}).forEach((key) => {
            if ((col.defaultValues as DefaultValue)[key]?.tableName === "files") {
              defaultValues[key] = (col.defaultValues as DefaultValue)[key]?.value;
            }
          });
        }
      }
    });
  }

  Object.keys(defaultValues).forEach((key) => {
    if (SPECIAL_DEFAULTS_VALUES.includes(defaultValues[key])) {
      const value = defaultValues[key];
      if (value === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID) {
        if (additionalValues.currentProjectId) {
          defaultValues[key] = additionalValues.currentProjectId;
        } else {
          delete defaultValues[key];
        }
      } else if (value === SPECIAL_DEFAULTS.CURRENT_RECORD_ID) {
        if (additionalValues.currentRecordId) {
          defaultValues[key] = additionalValues.currentRecordId;
        } else {
          delete defaultValues[key];
        }
      } else if (value === SPECIAL_DEFAULTS.CURRENT_USER_ID) {
        if (additionalValues.currentUserId) {
          defaultValues[key] = additionalValues.currentUserId;
        } else {
          delete defaultValues[key];
        }
      }
    }
  });
  return defaultValues;
};

export const getFileJoinTableDefaultValues = (
  allPages: Page[],
  pageId: string,
  additionalValues: { currentProjectId?: string; currentRecordId?: string; currentUserId?: string } = {}
) => {
  const page = allPages.find((page) => page.id === pageId);

  const defaultValues: RecordItem = {};
  if (page) {
    page.columns?.forEach((col) => {
      if (isBasicColumn(col)) {
        if (col.defaultValues?.value) {
          defaultValues[col.name] = col.defaultValues.value;
        } else {
          if (isObject(col.defaultValues)) {
            Object.keys(col.defaultValues || {}).forEach((key) => {
              if ((col.defaultValues as DefaultValue)[key]?.tableName === "files") {
                defaultValues[key] = (col.defaultValues as DefaultValue)[key]?.value;
              }
            });
          }
        }
      }
    });
  }

  Object.keys(defaultValues).forEach((key) => {
    if (SPECIAL_DEFAULTS_VALUES.includes(defaultValues[key])) {
      const value = defaultValues[key];
      if (value === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID) {
        if (additionalValues.currentProjectId) {
          defaultValues[key] = additionalValues.currentProjectId;
        } else {
          delete defaultValues[key];
        }
      } else if (value === SPECIAL_DEFAULTS.CURRENT_RECORD_ID) {
        if (additionalValues.currentRecordId) {
          defaultValues[key] = additionalValues.currentRecordId;
        } else {
          delete defaultValues[key];
        }
      } else if (value === SPECIAL_DEFAULTS.CURRENT_USER_ID) {
        if (additionalValues.currentUserId) {
          defaultValues[key] = additionalValues.currentUserId;
        } else {
          delete defaultValues[key];
        }
      }
    }
  });
  return defaultValues;
};

export const copyFileRawUrl = async (path: string) => {
  const fileUrl = buildSupabasePublicUrl(path);
  await copyText(buildSupabasePublicUrl(fileUrl));
};
