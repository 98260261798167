import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import isEqual from "lodash/isEqual";
import { PostgrestError } from "@supabase/supabase-js";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { FormJoinTableRelationInfo, TableColumnType } from "types/baTypes";
import { RecordItem, SelectOption } from "types/common";
import { AddInput, RemoveInput, UpdateInput } from "types/apiTypes";
import { constructInputForTableRecord } from "lib/utils";
import { hasCreatedInColumns } from "utils/formUtils";
import toast from "utils/toast";
import {
  ALTERNATE_SCHEMAS,
  CellType,
  EMPTY_FIELD,
  ERROR_TYPES,
  FORM_TRIGGER_TYPES,
  SPECIAL_DEFAULTS,
  TEXT_TYPE_CELLS,
  ViewOption
} from "utils/constants";
import useAddRecord from "./useAddRecord";
import useSchemaState from "./useSchemaState";
import useUpdateRecord from "./useUpdateRecord";
import useRemoveRecord from "./useRemoveRecord";
import useUpdateRecordByCompositeKey from "./useUpdateRecordByCompositeKey";
import useUIState from "./useUIState";
import useFilesProcessUpload from "./useFilesProcessUpload";
import useCreateFormRecord from "./useCreateFormRecord";
import useTableActionsState from "./useTableActionsState";
import useCurrentUser from "./useCurrentUser";
import useRecordTypes from "./useRecordTypes";
import useErrorLogger from "./useErrorLogger";
import useSearchQueryParams from "./useSearchQueryParams";
import useInfoLogger from "./useInfoLogger";

type FormFieldsSubmissionProps = {
  tableName: string;
  record?: RecordItem;
  formColumns: TableColumnType[];
  slug: string;
  joinTableRelationInfo?: FormJoinTableRelationInfo[];
  shouldRefetchTableData?: boolean;
  alternateSchema?: ALTERNATE_SCHEMAS; // If base page is using alternate schema
  skipDefaultValueSet?: boolean;
  trigger?: FORM_TRIGGER_TYPES;
  source?: string;
  formViewId?: string;
  pageFormViewId?: string;
};
const useFormFieldsSubmission = ({
  tableName,
  record,
  formColumns,
  slug,
  joinTableRelationInfo,
  shouldRefetchTableData = true,
  alternateSchema,
  skipDefaultValueSet = false,
  trigger,
  source,
  formViewId,
  pageFormViewId
}: FormFieldsSubmissionProps) => {
  const { showErrorToast } = useUIState();
  const { schemaInstance } = useSchemaState();
  const currentUser = useCurrentUser();
  const { logError } = useErrorLogger();
  const { logInfo } = useInfoLogger();
  const supabaseClient = useSupabaseBrowser();
  const { currentSearchContextProjectId, currentSearchContextRecordId } = useSearchQueryParams();

  const queryClient = useQueryClient();
  const { addRecordAsync, isLoading: isAddingRecordForm } = useAddRecord();
  const { updateRecordAsync, isLoading: isupdatingRecord } = useUpdateRecord();
  const { removeRecordAsync, isLoading: isRemovingRecord } = useRemoveRecord();
  const { updateRecordByCompositeKeyAsync } = useUpdateRecordByCompositeKey();
  const { uploadPercent, uploadFiles, uploadJoinTableFiles } = useFilesProcessUpload();
  const { currentProjectId, currentRecordId } = useTableActionsState();
  const { data: recordTypesData } = useRecordTypes();

  const processLookupTextFields = useCallback(
    async (
      input: RecordItem,
      errorLogAdditionalProps?: RecordItem
    ): Promise<
      | RecordItem
      | {
          success: boolean;
          addInputToDelete?: Array<{ tableName: string; id: string }>;
          inputResponses?: RecordItem;
          error?: Error | PostgrestError;
        }
    > => {
      const addQueriesInput: AddInput[] = [];
      const updateQueriesInput: UpdateInput[] = [];
      const lookupTextFieldColumns = formColumns.filter(
        (col) => (TEXT_TYPE_CELLS.includes(col.type) || col.type === CellType.ADDRESS) && col.isLookup
      );
      const finalInputToDelete: Array<{ tableName: string; id: string }> = [];
      const inputFields = Object.keys(input);
      inputFields.forEach((field) => {
        const fieldColumn = lookupTextFieldColumns.find((col) => col.lookupPath?.[0].lookupForeignKey === field);
        if (fieldColumn && fieldColumn.lookupPath?.[0]) {
          if (input[field].id) {
            updateQueriesInput.push({
              tableName: fieldColumn.lookupPath[0].lookupTableName,
              input: {
                ...input[field]
              }
            });
          } else {
            addQueriesInput.push({
              tableName: fieldColumn.lookupPath[0].lookupTableName,
              input: {
                ...input[field]
              }
            });
          }
        }
      });

      if (inputFields.length === addQueriesInput.length + updateQueriesInput.length) {
        const queriesResponse = await Promise.all([
          ...addQueriesInput.map((query) => addRecordAsync(query)),
          ...updateQueriesInput.map((query) => updateRecordAsync(query))
        ]);
        const addResponses = queriesResponse.slice(0, addQueriesInput.length);
        addResponses.forEach((resp, index) => {
          const addInputEntry = addQueriesInput[index];
          if (!resp.error) {
            finalInputToDelete.push({ tableName: addInputEntry.tableName, id: resp.data?.[0].id });
          }
        });
        const errorResponses = queriesResponse.filter((queryResponse) => queryResponse.error);
        if (errorResponses.length) {
          logError({
            error: new Error("Error adding lookup text field input"),
            source: "useFormFieldsSubmission - processLookupTextFields",
            type: ERROR_TYPES.HOOKS,
            message: `Error adding lookup text field input for record ${record?.id}`,
            url: window.location.href,
            additionalInfo: {
              errorResponses,
              inputFields,
              addQueriesInput,
              updateQueriesInput,
              ...errorLogAdditionalProps
            }
          });
          return { success: false, addInputToDelete: finalInputToDelete, error: errorResponses[0].error };
        }
        const inputResponses = inputFields.reduce((acc: RecordItem, field, index) => {
          acc[field] = queriesResponse[index].data[0].id;
          return acc;
        }, {});
        return { success: true, inputResponses };
      }
      return { success: false };
    },
    [addRecordAsync, formColumns, updateRecordAsync, logError, record?.id]
  );

  const {
    onCreateBaseTableData,
    onCreateJoinTableData,
    isAddingRecord,
    isUpdatingRecord,
    isRemovingRecord: isCreateRemovingRecord,
    uploadPercent: createUploadPercent
  } = useCreateFormRecord({
    processLookupTextFields,
    trigger
  });

  const isLoading =
    isAddingRecord ||
    isAddingRecordForm ||
    isupdatingRecord ||
    isUpdatingRecord ||
    isRemovingRecord ||
    isCreateRemovingRecord ||
    (uploadPercent > 0 && uploadPercent < 100) ||
    (createUploadPercent > 0 && createUploadPercent < 100);

  const rollBackCreatedRecords = useCallback(
    async (deleteInputs: Array<{ tableName: string; id: string }>) => {
      if (deleteInputs.length) {
        showErrorToast("Rolling back related table records entries", { autoClose: 5000 });
        await Promise.all(deleteInputs.map((input) => removeRecordAsync({ tableName: input.tableName, id: input.id })));
        showErrorToast("Rollback successful", { autoClose: 5000 });
      }
    },
    [removeRecordAsync, showErrorToast]
  );

  const handleFileListFilesUpdate = useCallback(
    async ({
      finalRecord,
      fileIds,
      createdInProps
    }: {
      finalRecord?: RecordItem;
      fileIds?: string[];
      createdInProps?: { createdIn: string; createdInPath: string };
    }) => {
      const newFileListId: RecordItem = {};
      let finalFilesListId: string | null = null;
      if (finalRecord?.files_list_id) {
        finalFilesListId = finalRecord.files_list_id;
      } else {
        if (fileIds?.length) {
          // First create a files list entry for this record
          const newListResp = await addRecordAsync({
            tableName: "files_lists",
            input: {
              created_in: createdInProps?.createdIn,
              created_in_path: createdInProps?.createdInPath,
              project_id:
                trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                  ? currentSearchContextProjectId
                  : currentProjectId
            }
          });
          if (newListResp?.data?.[0]?.id) {
            newFileListId["files_list_id"] = newListResp.data[0].id;
            finalFilesListId = newListResp?.data?.[0]?.id;
          }
        }
      }
      // Now update the join table files_lists_file
      if (fileIds?.length && finalFilesListId) {
        const fileJoinInputs = fileIds.map((fileId: string) => {
          return {
            files_lists_id: finalFilesListId,
            files_id: fileId,
            project_id:
              trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                ? currentSearchContextProjectId
                : currentProjectId
          };
        });
        await Promise.all(
          fileJoinInputs.map((input: RecordItem) => addRecordAsync({ tableName: "files_lists_files", input }))
        );
      }

      return newFileListId;
    },
    [addRecordAsync, currentProjectId, currentSearchContextProjectId, trigger]
  );

  const _onSubmitBaseTableData = useCallback(
    async ({
      finalTableName,
      inputFields,
      finalRecord,
      isFromBulkEdit,
      createdInProps,
      formColumns,
      errorLogAdditionalProps
    }: {
      finalTableName: string;
      inputFields: RecordItem;
      finalRecord?: RecordItem;
      isFromBulkEdit?: boolean;
      createdInProps?: { createdIn: string; createdInPath: string };
      formColumns?: TableColumnType[];
      errorLogAdditionalProps: RecordItem;
    }) => {
      const { joinTableInput, joinTableFileTagInput, files, filesListRemoveIds, json, ...recordInput } = inputFields;
      // creation Flow
      if (!finalRecord?.id) {
        const newRecord = await onCreateBaseTableData({
          inputFields,
          finalTableName,
          createdInProps,
          formColumns,
          errorLogAdditionalProps
        });
        return newRecord;
      } else {
        // update flow

        // all direct table record input fields
        let finalRecordInput: RecordItem = {};
        if (!isEmpty(recordInput)) {
          finalRecordInput = { ...finalRecordInput, ...recordInput };
          Object.keys(finalRecordInput).forEach((key) => {
            const recordValue = finalRecordInput[key];
            if (finalRecordInput[key] === EMPTY_FIELD) {
              finalRecordInput[key] = null;
            }
            const column = formColumns?.find((col) => col.name === key);
            const columnIsRequired = column && column?.views?.[ViewOption.FORM]?.isRequired;
            if (recordValue === SPECIAL_DEFAULTS.CURRENT_RECORD_ID) {
              if (trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextRecordId) {
                finalRecordInput[key] = currentSearchContextRecordId;
              } else if (currentRecordId) {
                finalRecordInput[key] = currentRecordId;
              } else {
                if (columnIsRequired) {
                  showErrorToast("Special default current record id not found.");
                } else {
                  finalRecordInput[key] = null;
                }
              }
            }
            if (recordValue === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID) {
              if (trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId) {
                finalRecordInput[key] = currentSearchContextProjectId;
              } else if (currentProjectId) {
                finalRecordInput[key] = currentProjectId;
              } else {
                if (columnIsRequired) {
                  showErrorToast("Special default current project id not found.");
                } else {
                  finalRecordInput[key] = null;
                }
              }
            }
            if (recordValue === SPECIAL_DEFAULTS.CURRENT_USER_ID) {
              if (currentUser?.id) {
                finalRecordInput[key] = currentUser.id;
              } else {
                if (columnIsRequired) {
                  showErrorToast("Special default current user id not found.");
                } else {
                  finalRecordInput[key] = null;
                }
              }
            }
          });

          // has foreignKey Field for text type
          const lookupTextFieldInput = Object.keys(finalRecordInput).reduce((acc: RecordItem, curr) => {
            if (isObject(recordInput[curr])) {
              acc[curr] = recordInput[curr];
            }
            return acc;
          }, {});
          if (!isEmpty(lookupTextFieldInput) && isEmpty(json)) {
            const lookupTextFieldResp = await processLookupTextFields(lookupTextFieldInput, errorLogAdditionalProps);
            if (lookupTextFieldResp.success) {
              finalRecordInput = {
                ...finalRecordInput,
                ...lookupTextFieldResp.inputResponses
              };
            } else {
              showErrorToast(
                `There was an error updating the lookup text fields. ${lookupTextFieldResp?.error?.message}}`
              );
              // If any new records were added revert them
              const inputsToDelete = lookupTextFieldResp.addInputToDelete;
              if (inputsToDelete?.length) {
                await rollBackCreatedRecords(inputsToDelete);
              }
              return;
            }
          }
        }

        // upload ForeignKey Files
        if (!isEmpty(files)) {
          const filesInput: RecordItem = {};
          const filesValueToRemove: RecordItem = {};
          const tableProps = schemaInstance?.extendedSchema?.[finalTableName];
          Object.keys(files).forEach((fileKey) => {
            if (files[fileKey] === EMPTY_FIELD) {
              filesValueToRemove[fileKey] = null;
            } else {
              filesInput[fileKey] = files[fileKey];
            }
          });
          if (!isEmpty(filesValueToRemove)) {
            finalRecordInput = { ...finalRecordInput, ...filesValueToRemove };
          }

          const uploadedFilesResp = await uploadFiles(
            filesInput,
            `${finalTableName}/${finalRecord.id}`,
            createdInProps
          );
          if (uploadedFilesResp) {
            // If files list id is the foreign key in files input we need to handle this by creating a new files list if not available
            // then updating the `files_list_files` join table
            if (
              tableProps?.attributeIds?.includes("files_list_id") ||
              tableProps?.attributeIds?.includes("files_lists_id")
            ) {
              const firstKey = Object.keys(uploadedFilesResp)?.[0];
              // This is not an array when column is not multiple
              const fileIds = Array.isArray(uploadedFilesResp[firstKey])
                ? [...uploadedFilesResp[firstKey]]
                : [uploadedFilesResp[firstKey]];
              if (firstKey) {
                const newFileListId = await handleFileListFilesUpdate({
                  fileIds,
                  finalRecord,
                  createdInProps
                });
                finalRecordInput = { ...finalRecordInput, ...newFileListId };
              }
            } else {
              finalRecordInput = { ...finalRecordInput, ...uploadedFilesResp };
            }
          } else {
            if (
              (tableProps?.attributeIds?.includes("files_list_id") ||
                tableProps?.attributeIds?.includes("files_lists_id")) &&
              Object.keys(files)?.includes("files_list_id")
            ) {
              // Check if files options are coming from Add Many
              const fileOptions: string[] = [];
              const fileArrays = files["files_list_id"] === EMPTY_FIELD ? [] : files["files_list_id"];
              fileArrays?.forEach((fileOption: SelectOption) => {
                // Is a file option
                if (fileOption?.optionData?.id && fileOption?.optionData?.path) {
                  fileOptions.push(fileOption?.optionData?.id);
                }
              });
              if (fileOptions.length) {
                const newFileListId = await handleFileListFilesUpdate({
                  fileIds: fileOptions,
                  finalRecord,
                  createdInProps
                });

                finalRecordInput = { ...finalRecordInput, ...newFileListId };
              }
            }
          }
        }
        if (!isEmpty(filesListRemoveIds)) {
          // These are only returned when column is files_list_id
          await Promise.all(
            filesListRemoveIds.map((id: string) => removeRecordAsync({ tableName: "files_lists_files", id }))
          );
        }

        // if atleast one direct relation table record field present update record
        if (!isEmpty(finalRecordInput)) {
          const updateRes = await updateRecordAsync({
            tableName: finalTableName,
            input: { id: finalRecord.id, ...finalRecordInput }
          });
          if (!updateRes.data?.length) {
            if (updateRes.error) {
              logError({
                error: updateRes.error,
                source: "useFormFieldsSubmission - onSubmitBaseTableData",
                type: ERROR_TYPES.HOOKS,
                message: updateRes.error.message || "There was an error updating the record",
                url: window.location.href,
                additionalInfo: {
                  finalTableName,
                  finalRecordInput,
                  finalRecordId: finalRecord.id,
                  inputFields,
                  createdInProps,
                  ...errorLogAdditionalProps
                }
              });
            }
            showErrorToast(`There was an error updating the record. ${updateRes.error?.message}`);
            return;
          }
        }
        if (!isEmpty(joinTableFileTagInput)) {
          const isFilesTable = finalTableName === "files";
          const joinTableAddQueriesInput: AddInput[] = [];
          const joinTableRemoveQueriesInput: RemoveInput[] = [];
          const { joinTableLookupNameToTableMap, ...joinFileTagInput } = joinTableFileTagInput;
          const joinTableInputFields = Object.keys(joinFileTagInput);

          joinTableInputFields.forEach((field) => {
            // Check if field column is multi select or not
            let isMultiSelect = false;
            formColumns?.forEach((col) => {
              if (col.name === field && col.isMultiple) {
                isMultiSelect = true;
                return true;
              }
              if (
                col.isLookup &&
                (col.lookupPath?.[0]?.lookupTableName === field || col.lookupPath?.[0]?.lookupColumnLabel === field) &&
                col.isMultiple
              ) {
                isMultiSelect = true;
                return true;
              }
            });
            const finalLookupTableName = joinTableLookupNameToTableMap[field];
            const inputEntry = joinFileTagInput[field];
            const finalRecordTags = isFilesTable ? finalRecord[field] : finalRecord[field]?.files_tags;

            if (finalRecordTags?.length && !isMultiSelect) {
              let fileId = Array.isArray(inputEntry) ? inputEntry[0]?.file_id : inputEntry?.file_id;
              if (inputEntry === EMPTY_FIELD) {
                fileId = finalRecordTags?.[0]?.file_id;
              }
              // Check if input entry is already present in the finalRecord
              finalRecordTags.forEach((joinEntry: RecordItem) => {
                // Join entry will be of the form { tag_id: { id: 1, name: "Tag" }
                // Or it can tag_id directly
                if ((joinEntry.tag_id?.id || joinEntry.tag_id) && fileId) {
                  const compositePrimaryKey = {
                    file_id: fileId,
                    tag_id: joinEntry.tag_id.id || joinEntry.tag_id
                  };
                  joinTableRemoveQueriesInput.push({ tableName: "files_tags", compositePrimaryKey });
                }
              });
              // Check if entry is removed (not present in inputEntry but is in finalRecord)
            }
            // Exclude already existing records when multiselect
            let finalNewEntries = inputEntry;
            if (isMultiSelect && Array.isArray(inputEntry) && !!inputEntry?.length) {
              // Add entries that are not present in record already
              finalNewEntries = inputEntry.filter((entry: RecordItem) => {
                const existingRecord = finalRecordTags?.find((joinEntry: RecordItem) => {
                  if ((joinEntry.tag_id?.id || joinEntry.tag_id) && entry?.tag_id) {
                    const compositePrimaryKey = {
                      file_id: entry.file_id,
                      tag_id: entry.tag_id
                    };
                    if (
                      (compositePrimaryKey?.tag_id === joinEntry.tag_id?.id ||
                        compositePrimaryKey?.tag_id === joinEntry.tag_id) &&
                      compositePrimaryKey?.file_id === joinEntry.file_id
                    ) {
                      return true;
                    }
                  }
                  return false;
                });
                return !existingRecord;
              });
            }
            if (Array.isArray(finalNewEntries) && !!finalNewEntries?.length) {
              finalNewEntries.forEach((inputObj: RecordItem) => {
                joinTableAddQueriesInput.push({
                  tableName: finalLookupTableName,
                  input: inputObj
                });
              });
            } else if (
              Array.isArray(inputEntry) &&
              !!inputEntry?.length &&
              finalRecordTags?.length > inputEntry?.length
            ) {
              // A tag was removed
              const tagIdsInFormEntry = inputEntry.map((entry: RecordItem) => entry.tag_id);

              finalRecordTags.forEach((joinEntry: RecordItem) => {
                // Join entry will be of the form { tag_id: { id: 1, name: "Tag" }
                // Or it can tag_id directly
                if (
                  (joinEntry.tag_id?.id || joinEntry.tag_id) &&
                  !tagIdsInFormEntry.includes(joinEntry.tag_id?.id || joinEntry.tag_id)
                ) {
                  const compositePrimaryKey = {
                    file_id: joinEntry.file_id,
                    tag_id: joinEntry.tag_id?.id || joinEntry.tag_id
                  };
                  joinTableRemoveQueriesInput.push({ tableName: "files_tags", compositePrimaryKey });
                }
              });
            } else if (Array.isArray(inputEntry) && !inputEntry?.length) {
              // It will happen when user removes all the tags from the file
              if (finalRecord[field]?.files_tags?.length) {
                finalRecord[field].files_tags.forEach((joinEntry: RecordItem) => {
                  // Join entry will be of the form { tag_id: { id: 1, name: "Tag", file_id: 1 }
                  if (joinEntry.tag_id?.id) {
                    const compositePrimaryKey = {
                      file_id: joinEntry.file_id,
                      tag_id: joinEntry.tag_id.id
                    };
                    joinTableRemoveQueriesInput.push({ tableName: "files_tags", compositePrimaryKey });
                  }
                });
              }
            } else if (inputEntry !== EMPTY_FIELD) {
              if (finalRecord[field]?.files_tags?.length) {
                // Check if input entry is already present in the finalRecord
                finalRecord[field].files_tags.forEach((joinEntry: RecordItem) => {
                  // Join entry will be of the form { tag_id: { id: 1, name: "Tag" }
                  if (joinEntry.tag_id?.id) {
                    const compositePrimaryKey = {
                      file_id: inputEntry.file_id,
                      tag_id: inputEntry.tag_id
                    };
                    joinTableRemoveQueriesInput.push({ tableName: "files_tags", compositePrimaryKey });
                  }
                });
              }

              joinTableAddQueriesInput.push({
                tableName: finalLookupTableName,
                input: inputEntry
              });
            }
            // if join relation exist remove it
          });

          let errorResponses: RecordItem[] = [];
          if (joinTableRemoveQueriesInput.length) {
            const joinRemoveQueriesResponse = await Promise.all(
              joinTableRemoveQueriesInput.map((input) => removeRecordAsync(input as RemoveInput))
            );
            errorResponses = [
              ...errorResponses,
              ...joinRemoveQueriesResponse.filter((queryResponse) => queryResponse.error)
            ];
          }
          if (joinTableAddQueriesInput.length) {
            const joinAddQueriesResponse = await Promise.all(
              joinTableAddQueriesInput.map((input) => addRecordAsync(input as AddInput))
            );
            errorResponses = [...joinAddQueriesResponse.filter((queryResponse) => queryResponse.error)];
          }
          if (errorResponses.length) {
            console.log("@@ Error while updating file tag table records ", errorResponses);
            const firstErrMsg = errorResponses[0]?.error?.message;
            if (firstErrMsg) {
              showErrorToast(`Error updating file tags: ${firstErrMsg}`);
            } else {
              showErrorToast(
                "There was an error updating the file tag table records. Please check all fields and try again."
              );
            }
            logError({
              error: new Error("Error one of the file tag table relation update for record failed"),
              source: "useFormFieldsSubmission - onSubmitBaseTableData",
              type: ERROR_TYPES.HOOKS,
              message: firstErrMsg || "There was an error updating the record",
              url: window.location.href,
              additionalInfo: {
                errorResponses,
                inputFields,
                joinTableAddQueriesInput,
                joinTableRemoveQueriesInput,
                createdInProps,
                ...errorLogAdditionalProps
              }
            });
            return;
          }
        }
        // update join table fields
        if (!isEmpty(joinTableInput)) {
          const joinTableAddQueriesInput: AddInput[] = [];
          const joinTableRemoveQueriesInput: RemoveInput[] = [];
          const joinTableUpdateQueriesInput: UpdateInput[] = [];
          const joinCompositeKeyUpdateQueriesInput: UpdateInput[] = [];
          const {
            files: joinTableFilesInput,
            joinTableLookupNameToTableMap,
            joinUpdateInput,
            joinCompositeKeyUpdate,
            ...joinTableInputFields
          } = joinTableInput;
          // upload join table files
          if (!isEmpty(joinTableFilesInput)) {
            const finalFilesInput: RecordItem = {};
            Object.keys(joinTableFilesInput).forEach((fileKey) => {
              if (joinTableFilesInput[fileKey] !== EMPTY_FIELD) {
                finalFilesInput[fileKey] = joinTableFilesInput[fileKey];
              }
            });
            const uploadedFilesInput = await uploadJoinTableFiles(
              finalFilesInput,
              `${finalTableName}/${finalRecord.id}`,
              joinTableLookupNameToTableMap,
              false,
              createdInProps
            );
            if (uploadedFilesInput) {
              uploadedFilesInput.forEach((fileInput) => {
                const recordKey = Object.keys(fileInput.input).find(
                  (key) => fileInput.input[key] === undefined && key !== "id"
                );
                if (recordKey) {
                  fileInput.input[recordKey] = finalRecord.id;
                }
                joinTableAddQueriesInput.push(fileInput);
              });
            }

            //remove existing files
            Object.keys(joinTableFilesInput).forEach((joinTableName) => {
              // if there are existing files
              if (finalRecord[joinTableName]?.length) {
                const filesFromJoinTable = Array.isArray(joinTableFilesInput[joinTableName])
                  ? [...joinTableFilesInput[joinTableName]]
                  : isObject(joinTableFilesInput[joinTableName])
                    ? [...joinTableFilesInput[joinTableName]]
                    : [];

                const finalLookupTableName = joinTableLookupNameToTableMap[joinTableName];
                const compositeKey = alternateSchema
                  ? schemaInstance?.alternateSchema?.[alternateSchema]?.[finalLookupTableName]?.compositePk
                  : schemaInstance?.extendedSchema[finalLookupTableName].compositePk;
                const fileAttributeKey = compositeKey?.find((key) => key.table === "files");

                if (compositeKey && fileAttributeKey) {
                  const fileKey = fileAttributeKey.attributeId;
                  const fileIdsToSkip: Array<string | number> = [];
                  filesFromJoinTable.forEach((compositeKeyInput) => {
                    if (!(compositeKeyInput[fileKey] instanceof File)) {
                      fileIdsToSkip.push(compositeKeyInput[fileKey].id);
                    }
                  });

                  const filesToDelete = finalRecord[joinTableName].filter(
                    (recordFile: RecordItem) => !fileIdsToSkip.includes(recordFile[fileKey].id)
                  );
                  filesToDelete.forEach((file: RecordItem) => {
                    const compositeKeyInput = compositeKey.reduce((acc: RecordItem, key) => {
                      acc[key.attributeId] = isObject(file[key.attributeId])
                        ? file[key.attributeId].id
                        : file[key.attributeId];
                      return acc;
                    }, {});
                    joinTableRemoveQueriesInput.push({
                      tableName: finalLookupTableName,
                      compositePrimaryKey: compositeKeyInput
                    });
                  });
                }
              }
            });
          }

          if (!isEmpty(joinUpdateInput)) {
            Object.keys(joinUpdateInput).forEach((joinTableName) => {
              const joinUpdateInputFields = Array.isArray(joinUpdateInput[joinTableName])
                ? [...joinUpdateInput[joinTableName]]
                : isObject(joinUpdateInput[joinTableName])
                  ? [{ ...joinUpdateInput[joinTableName] }]
                  : [];
              const finalLookupTableName = joinTableLookupNameToTableMap[joinTableName];
              if (joinUpdateInputFields.length) {
                const joinUpdateQueries = joinUpdateInputFields.map((input) => {
                  return { tableName: finalLookupTableName, input };
                });

                joinTableUpdateQueriesInput.push(...joinUpdateQueries);
              }
            });
          }

          if (!isEmpty(joinCompositeKeyUpdate)) {
            Object.keys(joinCompositeKeyUpdate).forEach((joinTableName) => {
              const joinUpdateCKInputFields = Array.isArray(joinCompositeKeyUpdate[joinTableName])
                ? [...joinCompositeKeyUpdate[joinTableName]]
                : isObject(joinCompositeKeyUpdate[joinTableName])
                  ? [{ ...joinCompositeKeyUpdate[joinTableName] }]
                  : [];
              const finalLookupTableName = joinTableLookupNameToTableMap[joinTableName];
              if (joinUpdateCKInputFields.length) {
                const joinUpdateQueries = joinUpdateCKInputFields.map((input) => {
                  return { tableName: finalLookupTableName, input };
                });

                joinCompositeKeyUpdateQueriesInput.push(...joinUpdateQueries);
              }
            });
          }

          Object.keys(joinTableInputFields).forEach((joinTable) => {
            const joinInputFields = Array.isArray(joinTableInput[joinTable])
              ? [...joinTableInput[joinTable]]
              : isObject(joinTableInput[joinTable])
                ? [{ ...joinTableInput[joinTable] }]
                : [];
            const finalLookupTableName = joinTableLookupNameToTableMap[joinTable];
            if (joinInputFields.length) {
              const joinInputQueries = joinInputFields
                .map((input) => {
                  const recordKey = Object.keys(input).find((key) => input[key] === undefined && key !== "id");
                  if (recordKey) {
                    input[recordKey] = finalRecord.id;
                  }

                  if (finalRecord[joinTable]?.length) {
                    const recordCompositePk = alternateSchema
                      ? schemaInstance?.alternateSchema?.[alternateSchema]?.[finalLookupTableName]?.compositePk
                      : schemaInstance?.extendedSchema[finalLookupTableName].compositePk;

                    // Check if the existing record already has this input
                    // Only return if it does not already exist
                    const existingRecord = finalRecord[joinTable].find((joinEntry: RecordItem) => {
                      const compositePrimaryKey = Object.keys(joinEntry).reduce((acc: RecordItem, key) => {
                        const finalEntry = isObject(joinEntry[key]) ? joinEntry[key].id : joinEntry[key];
                        // Only add composite key fields
                        if (!!finalEntry && recordCompositePk?.find((pk) => pk.attributeId === key)) {
                          acc[key] = finalEntry;
                        }
                        return acc;
                      }, {});

                      if (!isEmpty(compositePrimaryKey)) {
                        if (isEqual(input, compositePrimaryKey)) {
                          return true;
                        }
                      }
                      return false;
                    });

                    if (!isEmpty(existingRecord)) {
                      return null;
                    }
                  }
                  const { id, ...remainingInput } = input;
                  if (createdInProps?.createdIn) {
                    remainingInput.created_in = createdInProps.createdIn;
                    remainingInput.created_in_path = createdInProps.createdInPath;
                  }
                  return { tableName: finalLookupTableName, input: remainingInput };
                })
                .filter(Boolean);

              if (joinInputQueries && joinInputQueries?.length) {
                joinTableAddQueriesInput.push(...(joinInputQueries as Array<AddInput>));
              }
            }
            // if join relation exist remove it
            if (finalRecord[joinTable]?.length) {
              const recordCompositePk = alternateSchema
                ? schemaInstance?.alternateSchema?.[alternateSchema]?.[finalLookupTableName]?.compositePk
                : schemaInstance?.extendedSchema[finalLookupTableName].compositePk;
              const unMatchedRecords: RecordItem[] = [];
              // Expects all primary keys to be present
              finalRecord[joinTable].forEach((joinEntry: RecordItem) => {
                const compositePrimaryKey = Object.keys(joinEntry).reduce((acc: RecordItem, key) => {
                  const finalEntry = isObject(joinEntry[key]) ? joinEntry[key].id : joinEntry[key];

                  // Only add composite key fields
                  if (!!finalEntry && recordCompositePk?.find((pk) => pk.attributeId === key)) {
                    acc[key] = finalEntry;
                  }
                  return acc;
                }, {});
                // Should have the full composite primary key
                if (
                  !isEmpty(compositePrimaryKey) &&
                  Object.keys(compositePrimaryKey).length === recordCompositePk?.length
                ) {
                  // Find matching record in Add inputs
                  if (joinInputFields?.length) {
                    const matchedRecord = joinInputFields.find((joinInputEntry: RecordItem) =>
                      isEqual(joinInputEntry, compositePrimaryKey)
                    );
                    // In the case of bulk edit we are passing existing records
                    // selected from remove input so matched records need to be removed
                    if (isFromBulkEdit && matchedRecord) {
                      unMatchedRecords.push(compositePrimaryKey);
                    }

                    if (!matchedRecord && !isFromBulkEdit) {
                      unMatchedRecords.push(compositePrimaryKey);
                    }
                  } else {
                    // Last element was removed
                    unMatchedRecords.push(compositePrimaryKey);
                  }
                }
              });

              if (unMatchedRecords?.length) {
                unMatchedRecords.forEach((compositePrimaryKey) => {
                  joinTableRemoveQueriesInput.push({ tableName: finalLookupTableName, compositePrimaryKey });
                });
              }
            }
          });

          let joinUpdateQueryResponses: any = [];
          if (joinTableUpdateQueriesInput?.length) {
            joinUpdateQueryResponses = await Promise.all(
              joinTableUpdateQueriesInput.map((input) => updateRecordAsync(input as UpdateInput))
            );
          }
          let joinUpdateCKQueryResponses: any = [];
          if (joinCompositeKeyUpdateQueriesInput?.length) {
            joinUpdateCKQueryResponses = await Promise.all(
              joinCompositeKeyUpdateQueriesInput.map((input) => updateRecordByCompositeKeyAsync(input as UpdateInput))
            );
          }

          const joinRemoveQueriesResponse = await Promise.all(
            joinTableRemoveQueriesInput.map((input) => removeRecordAsync(input as RemoveInput))
          );
          const joinAddQueriesResponse = await Promise.all(
            joinTableAddQueriesInput.map((input) => addRecordAsync(input as AddInput))
          );
          const errorResponses = [
            ...joinAddQueriesResponse.filter((queryResponse) => queryResponse.error),
            ...joinRemoveQueriesResponse.filter((queryResponse) => queryResponse.error),
            ...joinUpdateQueryResponses.filter((queryResponse: any) => queryResponse.error),
            ...joinUpdateCKQueryResponses.filter((queryResponse: any) => queryResponse.error)
          ];
          if (errorResponses.length) {
            logError({
              error: new Error("Error one of the join table relation update for record failed"),
              source: "useFormFieldsSubmission - onSubmitBaseTableData",
              type: ERROR_TYPES.HOOKS,
              message: "There was an error updating join table records.",
              url: window.location.href,
              additionalInfo: {
                finalTableName,
                finalRecordId: finalRecord.id,
                errorResponses,
                joinTableAddQueriesInput,
                joinTableRemoveQueriesInput,
                createdInProps,
                inputFields,
                ...errorLogAdditionalProps
              }
            });
            const firstErrMsg = errorResponses[0]?.error?.message;
            if (!(errorResponses[0]?.error?.code === "23505" && isFromBulkEdit)) {
              if (firstErrMsg) {
                showErrorToast(`Error updating related table records: ${firstErrMsg}`);
              } else {
                showErrorToast(
                  "There was an error updating related table records. Please check all fields and try again."
                );
              }
            }

            return;
          }
        }
        queryClient.refetchQueries({ queryKey: ["record", slug], exact: false });
        return true;
      }
    },
    [
      addRecordAsync,
      processLookupTextFields,
      queryClient,
      removeRecordAsync,
      schemaInstance?.extendedSchema,
      schemaInstance?.alternateSchema,
      slug,
      updateRecordAsync,
      updateRecordByCompositeKeyAsync,
      uploadFiles,
      uploadJoinTableFiles,
      rollBackCreatedRecords,
      showErrorToast,
      onCreateBaseTableData,
      alternateSchema,
      handleFileListFilesUpdate,
      currentProjectId,
      currentUser,
      currentRecordId,
      logError,
      trigger,
      currentSearchContextProjectId,
      currentSearchContextRecordId
    ]
  );

  const _onSubmitJoinTableData = useCallback(
    async ({
      finalTableName,
      inputFields,
      finalRecord,
      createdInProps,
      formColumns,
      errorLogAdditionalProps
    }: {
      finalTableName: string;
      inputFields: RecordItem;
      finalRecord?: RecordItem;
      isFromBulkEdit?: boolean;
      formColumns: TableColumnType[];
      createdInProps?: { createdIn: string; createdInPath: string };
      errorLogAdditionalProps: RecordItem;
    }) => {
      const recordCompositePk = alternateSchema
        ? schemaInstance?.alternateSchema?.[alternateSchema]?.[finalTableName]?.compositePk
        : schemaInstance?.extendedSchema[finalTableName].compositePk;
      const { joinTableInput, joinTableFileTagInput, ...recordInput } = inputFields;
      // creation flow
      if (!finalRecord?.id) {
        const newRecord = await onCreateJoinTableData({
          createdInProps,
          inputFields,
          finalTableName,
          formColumns,
          errorLogAdditionalProps
        });
        return newRecord;
      } else {
        //updation flow
        if (!isEmpty(recordInput)) {
          const updateRecordResp = await updateRecordAsync({ tableName: finalTableName, input: recordInput });
          if (!updateRecordResp.data?.length) {
            if (updateRecordResp.error) {
              logError({
                error: updateRecordResp.error,
                source: "useFormFieldsSubmission - onSubmitJoinTableData",
                type: ERROR_TYPES.HOOKS,
                message: updateRecordResp.error?.message || "There was an error updating the record.",
                url: window.location.href,
                additionalInfo: {
                  finalTableName,
                  finalRecordId: finalRecord.id,
                  recordInput,
                  createdInProps,
                  inputFields,
                  ...errorLogAdditionalProps
                }
              });
            }
            showErrorToast("There was an error updating join table records. Please check all fields and try again.");
            return;
          }
        }

        // update joinTableInput Records
        if (!isEmpty(joinTableInput)) {
          const joinTableInputFields = Object.keys(joinTableInput);
          const joinTableQueriesInput: {
            field: string;
            queryInput: UpdateInput;
          }[] = [];
          joinTableInputFields.forEach((field) => {
            const key = recordCompositePk?.find((pk) => pk.attributeId === field);
            if (key) {
              joinTableQueriesInput.push({
                queryInput: {
                  tableName: key.table,
                  input: joinTableInput[field]
                },
                field
              });
            }
          });

          if (joinTableQueriesInput.length) {
            const JoinTableQueriesResponse = await Promise.all(
              joinTableQueriesInput.map((input) =>
                _onSubmitBaseTableData({
                  finalTableName: input.queryInput.tableName,
                  inputFields: { ...input.queryInput.input, joinTableFileTagInput },
                  finalRecord: {
                    id: finalRecord[input.field]
                  },
                  formColumns,
                  errorLogAdditionalProps
                })
              )
            );
            const errorResponses = JoinTableQueriesResponse.filter((queryResponse) => !queryResponse);
            if (errorResponses.length) {
              logError({
                error: new Error("Error one of the join table relation update for record failed"),
                source: "useFormFieldsSubmission - onSubmitJoinTableData",
                type: ERROR_TYPES.HOOKS,
                message: "There was an error updating join table records.",
                url: window.location.href,
                additionalInfo: {
                  finalTableName,
                  finalRecordId: finalRecord.id,
                  errorResponses,
                  createdInProps,
                  inputFields,
                  ...errorLogAdditionalProps
                }
              });
              const firstErrMsg = errorResponses[0]?.error?.message;
              if (firstErrMsg) {
                showErrorToast(`Error updating related join table records: ${firstErrMsg}`);
              } else {
                showErrorToast(
                  "There was an error updating related join table records. Please check your inputs and try again."
                );
              }

              return;
            }
            queryClient.refetchQueries({ queryKey: ["record", finalRecord.id], exact: false });
            return true;
          }
        }
        // Handles updating the files_tags table from either the files table or a join table to files
        if (!isEmpty(joinTableFileTagInput)) {
          const submitResponse = await _onSubmitBaseTableData({
            finalTableName,
            inputFields: { joinTableFileTagInput },
            finalRecord,
            formColumns,
            errorLogAdditionalProps
          });
          if (!submitResponse) {
            logError({
              error: new Error("Error updating join table file tags"),
              source: "useFormFieldsSubmission - onSubmitJoinTableData",
              type: ERROR_TYPES.HOOKS,
              message: "There was an error updating join table file tags.",
              url: window.location.href,
              additionalInfo: {
                finalTableName,
                finalRecordId: finalRecord.id,
                joinTableFileTagInput,
                createdInProps,
                inputFields,
                ...errorLogAdditionalProps
              }
            });
            showErrorToast("There was an error updating join table file tags. Please check your inputs and try again.");
            return;
          }
          queryClient.refetchQueries({ queryKey: ["record", finalRecord.id], exact: false });
          return true;
        }
      }
    },
    [
      _onSubmitBaseTableData,
      queryClient,
      schemaInstance?.extendedSchema,
      schemaInstance?.alternateSchema,
      updateRecordAsync,
      onCreateJoinTableData,
      showErrorToast,
      alternateSchema,
      logError
    ]
  );

  const handleErrorLogResolved = useCallback(
    async ({
      inputFields,
      finalRecord,
      tableName,
      errorLogAdditionalProps
    }: {
      inputFields: RecordItem;
      finalRecord?: RecordItem;
      tableName: string;
      errorLogAdditionalProps?: RecordItem;
    }) => {
      // We need to set all error logs that match this error as resolved
      const { type, source, message } = finalRecord || {};
      if (!type || !source || !message || !inputFields?.is_resolved) {
        if (inputFields?.is_resolved && finalRecord?.id) {
          await supabaseClient
            .from("error_logs")
            .update({
              is_resolved: inputFields.is_resolved
            })
            .eq("id", finalRecord.id);
        }
        return;
      }
      const { error } = await supabaseClient
        .from("error_logs")
        .update({
          is_resolved: inputFields.is_resolved
        })
        .eq("type", type)
        .eq("source", source)
        .eq("message", message);

      if (error) {
        logError({
          error,
          source: "useFormFieldsSubmission - handleErrorLogResolved",
          type: ERROR_TYPES.HOOKS,
          message: "There was an error updating the error logs.",
          url: window.location.href,
          additionalInfo: {
            inputFields,
            finalRecord,
            tableName,
            ...errorLogAdditionalProps
          }
        });
        toast.error("There was an error updating the error logs. Please check your inputs and try again.");
      } else {
        toast.success("Error logs updated successfully.");

        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
        queryClient.invalidateQueries({ queryKey: ["tableNavData", tableName] });
      }
    },
    [logError, supabaseClient, queryClient]
  );

  const _onSubmitCommon = useCallback(
    async (
      data: RecordItem,
      options?: {
        record?: RecordItem;
        ignoreJoinTableCheck?: boolean;
        isInlineCreate?: boolean;
        isFromBulkEdit?: boolean;
        createdIn?: string; // Used to update created_in and created_in_path columns when submitted from the Form
        createdInPath?: string; // Used to update created_in_path column
        finalFormColumns?: TableColumnType[]; // Sent from Matrix view where final form columns are decided on submission
        notFetchData?: boolean;
        onSuccess?: (newRecord: RecordItem) => void;
        inCustomView?: boolean; // temp fix for custom view submission
      }
    ) => {
      const finalRecord = options?.record || record;
      const isFromBulkEdit = options?.isFromBulkEdit; // Alters add / remove record behaviour
      const baseTableProps = schemaInstance?.extendedSchema?.[tableName];
      const isJoinTable = options?.ignoreJoinTableCheck ? false : !!baseTableProps?.compositePk?.length;
      const hasCreatedIn =
        baseTableProps && hasCreatedInColumns(baseTableProps) && options?.createdIn && options?.createdInPath;
      const finalColumns = options?.finalFormColumns?.length ? options?.finalFormColumns : formColumns;

      const finalBaseSchema = alternateSchema
        ? schemaInstance?.alternateSchema?.[alternateSchema]
        : schemaInstance?.extendedSchema;
      const inputFields = constructInputForTableRecord({
        columns: finalColumns,
        formFields: data,
        extendedSchema: finalBaseSchema,
        tableName: tableName,
        recordData: finalRecord,
        joinTableRelationInfo,
        isJoinTable,
        recordTypesData,
        skipDefaultValueSet,
        inCustomView: options?.inCustomView,
        source: `${source} - useFormFieldsSubmission`,
        logInfo
      });

      const errorLogAdditionalProps = {
        baseTableName: tableName,
        recordData: finalRecord,
        type: record ? "update" : "create",
        originalData: data,
        baseInputFields: inputFields,
        formSource: source,
        formViewId,
        pageFormViewId
      };

      if (isEmpty(inputFields)) {
        showErrorToast("There was an error creating record input. Please check all fields and try again.");
        logError({
          error: new Error("No direct fields present for creating record"),
          source: "useFormFieldsSubmission - _onSubmitCommon",
          type: ERROR_TYPES.HOOKS,
          message: `Error submitting form as no input fields are added for table ${tableName}`,
          url: window.location.href,
          additionalInfo: {
            inputFields,
            data,
            finalRecord,
            tableName,
            createdIn: options?.createdIn,
            createdInPath: options?.createdInPath,
            ...errorLogAdditionalProps
          }
        });
        return;
      }

      // ## Hardcoded condition for error_logs table
      if (tableName === "error_logs" && inputFields?.is_resolved) {
        handleErrorLogResolved({ inputFields, finalRecord, tableName, errorLogAdditionalProps });
        return;
      }

      let newRecord;
      if (isJoinTable) {
        newRecord = await _onSubmitJoinTableData({
          finalTableName: tableName,
          inputFields,
          finalRecord,
          isFromBulkEdit,
          createdInProps: hasCreatedIn
            ? { createdIn: options?.createdIn || "", createdInPath: options?.createdInPath || "" }
            : undefined,
          formColumns: finalColumns,
          errorLogAdditionalProps
        });
      } else {
        newRecord = await _onSubmitBaseTableData({
          finalTableName: tableName,
          inputFields,
          finalRecord,
          isFromBulkEdit,
          createdInProps: hasCreatedIn
            ? { createdIn: options?.createdIn || "", createdInPath: options?.createdInPath || "" }
            : undefined,
          formColumns: finalColumns,
          errorLogAdditionalProps
        });
      }
      options?.onSuccess?.(newRecord);
      queryClient.invalidateQueries({ queryKey: ["records"] });

      if (shouldRefetchTableData && !options?.notFetchData) {
        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
        queryClient.invalidateQueries({ queryKey: ["tableNavData", tableName] });
      }
      return newRecord;
    },
    [
      _onSubmitBaseTableData,
      _onSubmitJoinTableData,
      formColumns,
      joinTableRelationInfo,
      queryClient,
      record,
      schemaInstance?.extendedSchema,
      schemaInstance?.alternateSchema,
      tableName,
      shouldRefetchTableData,
      showErrorToast,
      alternateSchema,
      recordTypesData,
      skipDefaultValueSet,
      handleErrorLogResolved,
      logError,
      logInfo,
      source,
      formViewId,
      pageFormViewId
    ]
  );

  const onSubmit = useCallback(
    (data: RecordItem) => {
      const newItem = _onSubmitCommon(data);

      return !!newItem;
    },
    [_onSubmitCommon]
  );

  const onSubmitWithRecord = useCallback(
    (
      data: RecordItem,
      options?: {
        isInlineCreate?: boolean;
        createdIn?: string; // Used to update created_in and created_in_path columns when submitted from the Form
        createdInPath?: string; // Used to update created_in_path column
        finalFormColumns?: TableColumnType[]; // Sent if form view has default values
      }
    ) => {
      const newItem = _onSubmitCommon(data, options);
      return newItem;
    },
    [_onSubmitCommon]
  );

  const onSubmitWithOptions = useCallback(
    (
      data: RecordItem,
      options: {
        record?: RecordItem;
        ignoreJoinTableCheck?: boolean;
        isFromBulkEdit?: boolean;
        createdIn?: string; // Used to update created_in and created_in_path columns when submitted from the Form
        createdInPath?: string; // Used to update created_in_path column
        finalFormColumns?: TableColumnType[];
        notFetchData?: boolean;
        onSuccess?: (newRecord: RecordItem) => void;
        inCustomView?: boolean;
      }
    ) => {
      const newItem = _onSubmitCommon(data, options);
      return newItem;
    },
    [_onSubmitCommon]
  );

  return {
    onFormSubmit: onSubmit,
    onFormSubmitWithRecord: onSubmitWithRecord,
    onFormSubmitWithOptions: onSubmitWithOptions,
    isLoading
  };
};

export default useFormFieldsSubmission;
