import chunk from "lodash/chunk";
import { useCallback, useState } from "react";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { uploadFileToStorage } from "lib/supabaseApi";
import { FileUploadInput, FileUploadResults, RecordItem } from "types/common";
import useAddRecord from "hooks/useAddRecord";
import useErrorLogger from "hooks/useErrorLogger";
import { ERROR_TYPES } from "utils/constants";

const useMultiFilesUpload = () => {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<Record<string, string>>({});
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();

  const { addRecordAsync: addFile } = useAddRecord();

  const startUpload = useCallback(
    async (files: FileUploadInput[], skipFileAdd = false): Promise<FileUploadResults> => {
      setUploadPercent(0);
      const updatedUploadStatus: RecordItem = {};
      files.forEach((fileInfo) => {
        updatedUploadStatus[fileInfo.name] = "Started";
      });
      setUploadStatus((prevState) => ({
        ...prevState,
        ...updatedUploadStatus
      }));
      setUploadPercent(5);
      let uploadCount = 0;
      const uploadedFiles: Array<FileUploadInput> = [];
      let errorFileData: Array<FileUploadInput> = [];
      const percentIncrementPerFile = 95 / files.length;
      const filesSet = chunk(files, files.length);
      for (let i = 0; i < filesSet.length; i++) {
        const filesInSet = filesSet[i];
        const finalRequestsInput = [];
        const finalDBRequestsInput = [];
        const fileDBUploadMap: Record<number, FileUploadInput> = {};

        // Collect all requests
        for (let j = 0; j < filesInSet.length; j++) {
          const { file, ...fileInfo } = filesInSet[j];

          finalRequestsInput.push({
            file: file,
            bucket: "files",
            path: fileInfo.path
          });
          if (!skipFileAdd) {
            finalDBRequestsInput.push({ tableName: "files", input: fileInfo });
            fileDBUploadMap[j] = filesInSet[j];
          }
        }

        try {
          await Promise.all(
            finalRequestsInput.map((fileReqInput) => uploadFileToStorage(supabaseClient, fileReqInput))
          );
          if (finalDBRequestsInput?.length) {
            const uploadedFilesResponse = await Promise.all(
              finalDBRequestsInput.map((dbReqInput) => addFile(dbReqInput))
            );
            uploadedFilesResponse.forEach((fileResp, index) => {
              if (fileResp.status === 201 && fileResp.data?.length) {
                uploadedFiles.push(fileResp.data[0]);
              } else {
                errorFileData.push(fileDBUploadMap[index]);
              }
            });
            setUploadStatus((prevStatus) => {
              const updatedStatus: RecordItem = {};
              uploadedFilesResponse.forEach((fileResp, index) => {
                updatedStatus[fileDBUploadMap[index]?.name] = fileResp.status === 201 ? "Completed" : "Failed";
              });

              return {
                ...prevStatus,
                ...updatedStatus
              };
            });
          }
          uploadCount += finalRequestsInput.length;
        } catch (err: any) {
          logError({
            error: err as Error,
            message: err.message || "Error uploading files",
            source: "useMultiFilesUpload - startUpload",
            type: ERROR_TYPES.HOOKS,
            url: window.location.href,
            additionalInfo: {
              finalRequestsInput
            }
          });
          setUploadStatus((prevState) => {
            const updatedStatus = filesInSet.reduce((acc: RecordItem, file) => {
              acc[file.name] = "Failed";
              return acc;
            }, {});
            return {
              ...prevState,
              ...updatedStatus
            };
          });
          errorFileData = errorFileData.concat(filesInSet);
        } finally {
          setUploadPercent((i + 1) * percentIncrementPerFile + 5);
        }
      }
      setUploadPercent(100);
      return { uploadCount, uploadedFiles, errorFileData };
    },
    [addFile, supabaseClient, logError]
  );

  const rejectFiles = useCallback((rejectedFiles: File[]) => {
    const updatedUploadStatus: RecordItem = {};
    rejectedFiles.forEach((fileInfo) => {
      updatedUploadStatus[fileInfo.name] = "Failed";
    });
    setUploadStatus((prevState) => ({
      ...prevState,
      ...updatedUploadStatus
    }));
  }, []);

  return {
    uploadStatus,
    uploadPercent,
    startUpload,
    rejectFiles
  };
};

export default useMultiFilesUpload;
