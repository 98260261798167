import isEmpty from "lodash/isEmpty";
import { COLUMN_CONDITIONAL_VISIBILITY } from "components/Admin/utils";
import { testRule } from "components/DataGridView/utils";
import { readLookupFieldsForCellGroupFormInput, readLookupFieldsForCellGroups } from "lib/utils";
import { ColumnConditionalRule, Page, TableColumnType, TableViewConfig } from "types/baTypes";
import { RecordItem } from "types/common";
import {
  getFinalVisibilityFromColumnRule,
  getFormulaResultForColumnForForm,
  isLookupForeignKeyOnJoinTable
} from "utils/columnUtils";
import { AdditionalFormActions, CrudActions, ViewOption } from "utils/constants";
import { getFileIdFromRecordBasedOnLoookupPath, isColumnFileTag } from "utils/dataUtils";
import { getForeignKeyOnJoinTableInput } from "utils/formUtils";
import { TableSchema } from "utils/schema";
import { generatePageTitle } from "utils/texts";

export type RelationRecordsData = {
  columns?: TableColumnType[];
  data: RecordItem;
};

export type FormInput = {
  id: string;
  name: string;
  label: string;
  type: string;
  isError: boolean;
  isReadOnly: boolean;
  column: TableColumnType | null;
  columnInitValue: any;
  onChangeCallback?: (
    value: string | number | boolean | RecordItem,
    colId: string,
    setValue: (name: string, value: any, config?: any) => void
  ) => void;
};

export const getFormColumnValue = (
  column: TableColumnType,
  recordData: RecordItem,
  relationRecordsData: RelationRecordsData[]
) => {
  const finalRecord =
    relationRecordsData.find((relationRecord) => relationRecord.columns?.some((col) => col.id === column.id))?.data ??
    recordData;
  return column.isLookup ? readLookupFieldsForCellGroupFormInput(column, finalRecord) : finalRecord[column.name];
};

export const getPageTitle = (action: string, title: string, showAdd?: boolean, pageTitle?: string) => {
  const finalTitle = title || pageTitle;
  const entity = generatePageTitle(finalTitle);

  const shouldAddPrefix = !(
    entity.toLowerCase().startsWith("add") ||
    entity.toLowerCase().startsWith("create") ||
    entity.toLowerCase().startsWith("edit")
  );
  switch (action) {
    case CrudActions.CREATE:
      return shouldAddPrefix ? `${showAdd ? "Add" : "Create"} ${entity}` : `${entity}`;
    case AdditionalFormActions.EMAIL:
      return "Create Email";
    case CrudActions.UPDATE:
    default:
      return shouldAddPrefix ? `Edit ${entity}` : `${entity}`;
  }
};

export const getSettingsForFormColumn = ({
  column,
  viewConfig,
  isJoinTable,
  relationFormColumnPages
}: {
  column: TableColumnType;
  viewConfig?: TableViewConfig;
  isJoinTable: boolean;
  relationFormColumnPages: Page[];
}) => {
  let isRequired = !!viewConfig?.requiredColumns?.includes(column.id);

  if (isJoinTable) {
    relationFormColumnPages.forEach((page) => {
      const formViewConfig = page?.views?.find((view) => view.viewType === ViewOption.FORM && view.isDefault);
      if (formViewConfig?.requiredColumns?.length) {
        isRequired = !!formViewConfig?.requiredColumns?.includes(column.id);
      }
    });
  }
  return {
    isRequired
  };
};

export const getRelationFormColumnPagesMap = (columns: TableColumnType[], allPages: Page[]) => {
  const formRelationColumns = columns.filter(
    (column) => column.views?.[ViewOption.FORM]?.id && !!column.views?.[ViewOption.FORM]?.formRelationPageId
  );
  return formRelationColumns.reduce((acc: { [key: string]: Page }, column) => {
    const relationPage = allPages?.find((page) => page.id === column.views?.[ViewOption.FORM]?.formRelationPageId);
    if (relationPage && relationPage.columns) {
      acc[column.name] = relationPage;
    }
    return acc;
  }, {});
};

export const getColumnForeignKeyOnTable = (column: TableColumnType) => {
  if (!column.isLookup) return false;
  const { lookupPath } = column;
  const firstLookupLevel = lookupPath?.["0"];

  return firstLookupLevel?.lookupForeignKey;
};

export const getCompositeKeyRecordForFilesTags = ({
  recordData,
  formData,
  column,
  extendedSchema
}: {
  recordData: RecordItem[];
  formData: RecordItem;
  column: TableColumnType;
  extendedSchema: { [tablename: string]: TableSchema };
}) => {
  const isFilesTag = isColumnFileTag(column);
  if (!isFilesTag) return null;
  // Construct for each selected record
  const inputsForTable: any = {
    tableName: "files_tags",
    add: [],
    remove: []
  };
  const formInputForColumn = formData[column.id];
  const formRemoveInputForColumn = formData[`${column.id}_remove`];
  if (isEmpty(formInputForColumn) && isEmpty(formRemoveInputForColumn)) {
    return null;
  }
  // Add handling for files_tags table bulk update
  const compositePrimaryKey = extendedSchema["files_tags"].compositePk;
  const tagsPK = compositePrimaryKey?.filter((pk) => pk.table === "tags")?.[0];
  if (!tagsPK || !recordData) {
    return {};
  }
  const compositeKeyForBaseTable = compositePrimaryKey.find((cKeyItem) => cKeyItem.table === "files");
  // Currently handles case with only TWO composity keys
  const secondComposityKeyProp = compositePrimaryKey.find((cKeyItem) => cKeyItem.table !== "files");
  if (!compositeKeyForBaseTable || !secondComposityKeyProp) {
    return null;
  }
  recordData.forEach((record) => {
    const fileRecord = getFileIdFromRecordBasedOnLoookupPath(record, column);
    const existingIds = !isEmpty(fileRecord?.files_tags)
      ? fileRecord?.files_tags
          ?.map((item: RecordItem) => (item?.tag_id?.id ? `${item.tag_id.id}` : null))
          .filter(Boolean)
      : [];

    if (column.isMultiple) {
      if (formInputForColumn?.inputValue?.length) {
        formInputForColumn?.inputValue.forEach((inputVal: any) => {
          // Add selected options if not already present
          if (inputVal.record?.id && !existingIds?.includes(`${inputVal.record?.id || ""}`)) {
            inputsForTable.add.push({
              [compositeKeyForBaseTable.attributeId]: fileRecord.id,
              [secondComposityKeyProp.attributeId]: inputVal.record?.id
            });
          }
        });
      }
      if (formRemoveInputForColumn?.inputValue?.length) {
        formRemoveInputForColumn?.inputValue.forEach((inputVal: any) => {
          // Remove selected options if already present
          if (inputVal.record?.id && existingIds?.includes(`${inputVal.record?.id || ""}`)) {
            inputsForTable.remove.push({
              [compositeKeyForBaseTable.attributeId]: fileRecord.id,
              [secondComposityKeyProp.attributeId]: inputVal.record?.id
            });
          }
        });
      }
    } else {
      const existingID = existingIds?.[0];
      if (formInputForColumn?.inputValue?.record?.id) {
        inputsForTable.add.push({
          [compositeKeyForBaseTable.attributeId]: fileRecord.id,
          [secondComposityKeyProp.attributeId]: formInputForColumn.inputValue.record.id
        });
        if (existingID) {
          inputsForTable.remove.push({
            [compositeKeyForBaseTable.attributeId]: fileRecord.id,
            [secondComposityKeyProp.attributeId]: existingID
          });
        }
      }
    }
  });
  return inputsForTable;
};

// This method returns all the composite key properties from a record based on lookup path
export const getCompositeKeyRecordsFromRecordData = ({
  recordData,
  formData,
  column,
  extendedSchema,
  baseTableName
}: {
  recordData: RecordItem[];
  formData: RecordItem;
  column: TableColumnType;
  extendedSchema: { [tablename: string]: TableSchema };
  baseTableName: string;
}) => {
  const { lookupPath } = column;
  if (!lookupPath) return null;
  const firstLookupLevel = lookupPath?.["0"];

  if (!firstLookupLevel || firstLookupLevel?.lookupForeignKey) return null;
  const { lookupTableName = "" } = firstLookupLevel;
  const lookupTableSchema = extendedSchema?.[lookupTableName];
  if (!lookupTableSchema?.compositePk?.length) return null;
  const { compositePk } = lookupTableSchema;

  // Construct for each selected record
  const inputsForTable: any = {
    tableName: lookupTableName,
    add: [],
    remove: [],
    updateJoin: []
  };
  const formInputForColumn = formData[column.id];
  const formRemoveInputForColumn = column.isMultiple ? formData[`${column.id}_remove`] : null;
  const isColForeignKeyOnJoin = isLookupForeignKeyOnJoinTable(column, extendedSchema);
  if (isEmpty(formInputForColumn) && isEmpty(formRemoveInputForColumn)) {
    return null;
  }

  const compositeKeyForBaseTable = compositePk.find((cKeyItem) => cKeyItem.table === baseTableName);
  // Currently handles case with only TWO composity keys
  const secondComposityKeyProp = compositePk.find((cKeyItem) => cKeyItem.table !== baseTableName);
  if (!compositeKeyForBaseTable || !secondComposityKeyProp) return null;
  // Check if column options is picking from this table
  const { columnOptionsLookUp } = column;
  if (isEmpty(columnOptionsLookUp) || columnOptionsLookUp?.["0"]?.isCustomSelectOptions) return null;
  const firstColOptionsLookupLevel = columnOptionsLookUp?.["0"];
  if (!firstColOptionsLookupLevel) return null;
  const { lookupTableName: columnOptionsLookupTableName } = firstColOptionsLookupLevel;
  if (columnOptionsLookupTableName !== secondComposityKeyProp.table && !isColForeignKeyOnJoin) return null;

  recordData.forEach((record) => {
    const existingRecordValue = readLookupFieldsForCellGroups(column, record, extendedSchema);
    const existingIds = !isEmpty(existingRecordValue) ? existingRecordValue?.map((item: any) => `${item.id}`) : [];
    if (column.isMultiple) {
      if (formInputForColumn?.inputValue?.length) {
        formInputForColumn?.inputValue.forEach((inputVal: any) => {
          // Add selected options if not already present
          if (inputVal.record?.id && !existingIds?.includes(`${inputVal.record?.id || ""}`)) {
            inputsForTable.add.push({
              [compositeKeyForBaseTable.attributeId]: record.id,
              [secondComposityKeyProp.attributeId]: inputVal.record?.id
            });
          }
        });
      }
      if (formRemoveInputForColumn?.inputValue?.length) {
        formRemoveInputForColumn?.inputValue.forEach((inputVal: any) => {
          // Remove selected options if already present
          if (inputVal.record?.id && existingIds?.includes(`${inputVal.record?.id || ""}`)) {
            inputsForTable.remove.push({
              [compositeKeyForBaseTable.attributeId]: record.id,
              [secondComposityKeyProp.attributeId]: inputVal.record?.id
            });
          }
        });
      }
    } else {
      const existingID = existingIds?.[0];
      if (isColForeignKeyOnJoin) {
        const finalInput = getForeignKeyOnJoinTableInput({
          recordData: record,
          column,
          extendedSchema,
          formInput: formData
        });
        inputsForTable.updateJoin.push(finalInput);
      }
      if (formInputForColumn?.inputValue?.record?.id && !isColForeignKeyOnJoin) {
        inputsForTable.add.push({
          [compositeKeyForBaseTable.attributeId]: record.id,
          [secondComposityKeyProp.attributeId]: formInputForColumn.inputValue.record.id
        });
        if (existingID) {
          inputsForTable.remove.push({
            [compositeKeyForBaseTable.attributeId]: record.id,
            [secondComposityKeyProp.attributeId]: existingID
          });
        }
      }
    }
  });

  return inputsForTable;
};

export const getFormulaColumnValue = ({
  formulaColId,
  formColumns,
  allFormValues,
  rollupUpdatedRecord,
  inForm = true
}: {
  formulaColId: string;
  formColumns: TableColumnType[];
  allFormValues?: RecordItem;
  rollupUpdatedRecord: RecordItem;
  inForm?: boolean;
}) => {
  const formulaColumn = formColumns.find((col: TableColumnType) => col.id === formulaColId);
  if (formulaColumn?.formula) {
    try {
      const formulaResult = getFormulaResultForColumnForForm({
        formulaColumn,
        allColumns: formColumns,
        record: {
          ...(allFormValues || {}),
          ...rollupUpdatedRecord
        },
        isForm: inForm
      });
      if (formulaResult) {
        return formulaResult;
      }
    } catch (error) {
      console.log("@@ Error in formula column  err", error, formulaColumn);
    }
  }
  return null;
};

export const getRollupFormulaColumnValues = ({
  rollupUpdatedColumnIds,
  allFormValues,
  formFormulaFieldsMap,
  formulaFieldIds,
  formColumns,
  inForm = true
}: {
  rollupUpdatedColumnIds: string[];
  allFormValues?: RecordItem;
  formFormulaFieldsMap: Record<string, string[]>;
  formulaFieldIds: string[];
  formColumns: TableColumnType[];
  inForm?: boolean;
}) => {
  const updatedFormulaMap = { ...formFormulaFieldsMap };
  const rollupUpdatedRecord = { ...allFormValues };
  const finalUpdatedResults: { [colId: string]: string | null } = {};
  rollupUpdatedColumnIds.forEach((colId: string) => {
    // Check if column is in any formula
    if (formulaFieldIds.includes(colId)) {
      const linkedFormulaColumnId = Object.keys(formFormulaFieldsMap || {}).find((formulaColId: string) => {
        return formFormulaFieldsMap[formulaColId].includes(colId);
      });
      if (linkedFormulaColumnId) {
        const formulaColumn = formColumns.find((col: TableColumnType) => col.id === linkedFormulaColumnId);
        if (formulaColumn?.formula) {
          try {
            const formulaResult = getFormulaResultForColumnForForm({
              formulaColumn,
              allColumns: formColumns,
              record: {
                ...allFormValues,
                ...rollupUpdatedRecord
              },
              isForm: inForm
            });
            if (Number.isFinite(formulaResult)) {
              finalUpdatedResults[linkedFormulaColumnId] = formulaResult;
              const finalPropName = inForm ? linkedFormulaColumnId : formulaColumn?.name;
              rollupUpdatedRecord[finalPropName] = formulaResult;
              // Remove entry from formulaMap
              delete updatedFormulaMap[linkedFormulaColumnId];
            }
          } catch (error) {
            console.log("@@ Error calculating formula ", error);
          }
        }
      }
    }
  });
  while (!isEmpty(updatedFormulaMap)) {
    Object.keys(updatedFormulaMap).forEach((colId: string) => {
      const formulaResult = getFormulaColumnValue({
        formulaColId: colId,
        formColumns,
        allFormValues,
        rollupUpdatedRecord,
        inForm
      });
      if (Number.isFinite(formulaResult)) {
        const formulaColumn = !inForm ? formColumns.find((col: TableColumnType) => col.id === colId) : null;
        if (!inForm && formulaColumn?.name) {
          rollupUpdatedRecord[formulaColumn?.name] = formulaResult;
        } else {
          rollupUpdatedRecord[colId] = formulaResult;
        }
        finalUpdatedResults[colId] = formulaResult;
      }
      // Remove entry from formulaMap
      delete updatedFormulaMap[colId];
    });
  }
  return finalUpdatedResults;
};

export const isFormConditionalColumnToShow = (
  changedColumn: TableColumnType,
  conditionalColumn: TableColumnType,
  value: any
) => {
  const checkRulePassed = (colRule: ColumnConditionalRule) => {
    if (!value || !conditionalColumn?.conditionalViewRules) {
      return false;
    }

    if (changedColumn?.isLookup && colRule?.field) {
      if (Array.isArray(value)) {
        return value.some((val: any) => {
          if (val.optionData && val.optionData[colRule?.field as string]) {
            return testRule(val.optionData[colRule?.field as string], colRule);
          }
          if (val.record && val.record[colRule?.field as string]) {
            return testRule(val.record[colRule?.field as string], colRule);
          }
          return false;
        });
      } else {
        if (value.optionData && value.optionData[colRule?.field as string]) {
          return testRule(value.optionData[colRule?.field], colRule);
        }
        if (value.record && value.record[colRule?.field as string]) {
          return testRule(value.record[colRule?.field], colRule);
        }
      }
    } else if (colRule) {
      return testRule(value, colRule);
    }

    return false;
  };

  return (
    getFinalVisibilityFromColumnRule({ columnRule: conditionalColumn.conditionalViewRules, checkRulePassed }) ===
    COLUMN_CONDITIONAL_VISIBILITY.SHOW
  );
};
