import React, { useCallback } from "react";
import clsx from "clsx";
import noop from "lodash/noop";
import { LucideIcon, Loader as SpinnerIcon } from "lucide-react";
import { ButtonSize, ButtonIconPosition, ButtonAppearance } from "utils/constants";
import Skeleton from "components/Skeleton";
import IconWithName from "components/Icon";
type ButtonProps = {
  label: string;
  size?: ButtonSize;
  icon?: LucideIcon | ((props: any) => JSX.Element) | null;
  iconName?: string;
  iconPosition?: ButtonIconPosition;
  appearance?: ButtonAppearance;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  isLoading?: boolean;
  isDataLoading?: boolean;
  as?: React.ReactElement;
  disabled?: boolean;
  count?: number;
  mRef?: React.RefObject<HTMLButtonElement> | null;
  classNameIcon?: string;
  showCountIndicator?: boolean; // to show the count indicator but with no count value
  href?: string;
};

const Button = React.forwardRef(
  (
    {
      label = "",
      size = ButtonSize.XS,
      icon,
      iconName,
      iconPosition = ButtonIconPosition.LEFT,
      appearance = ButtonAppearance.PRIMARY,
      onClick = noop,
      isLoading = false,
      isDataLoading = false,
      className = "",
      classNameIcon = "",
      as = <button />,
      mRef = null,
      disabled,
      count,
      showCountIndicator = false,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const clsSpinnerSize = {
      [ButtonSize.XS]: "h-2 w-2",
      [ButtonSize.SM]: "h-3 w-3",
      [ButtonSize.MD]: "h-5 w-5"
    };
    const clsButtonSpacing = {
      [ButtonSize.XS]: "px-3 h-9 py-2 text-sm font-medium",
      [ButtonSize.SM]: "px-4 h-8 text-sm font-medium",
      [ButtonSize.MD]: "px-[1.40625rem] h-14 text-md font-medium"
    };
    const clsButtonStyling = {
      [ButtonAppearance.PRIMARY]: clsx(
        "text-sm font-medium transition-colors bg-[#171717] dark:bg-[#ededed] text-[#ffffff] dark:text-[#0a0a0a]",
        "hover:bg-[#383838] dark:hover:bg-[#cccccc] active:bg-primary-900"
      ),
      [ButtonAppearance.SECONDARY]: clsx(
        "text-primary border border-neutral-300 hover:bg-neutral-200 active:bg-neutral-300 shadow-50 hover:shadow-none active:shadow-none",
        "dark:border-neutral-dark-300 dark:hover:bg-neutral-dark-200 dark:active:bg-neutral-dark-300"
      ),
      [ButtonAppearance.RED]: "text-neutral-0 bg-red-700 hover:bg-red-800 active:bg-red-900",
      [ButtonAppearance.TERTIARY]: "text-primary-700 bg-transparent hover:bg-primary-50 underline underline-offset-2",
      [ButtonAppearance.NEUTRAL]: "text-primary bg-neutral-200 hover:bg-neutral-200 active:bg-neutral-200"
    };

    const renderIcon = useCallback(
      (
        icon?: LucideIcon | ((props: any) => JSX.Element) | null,
        iconName?: string,
        iconPosition: ButtonIconPosition = ButtonIconPosition.LEFT
      ) => {
        const clsIconSize = {
          [ButtonSize.XS]: "h-4 w-4",
          [ButtonSize.SM]: "h-4 w-4",
          [ButtonSize.MD]: "h-4 w-4"
        };
        const classNamePosition = {
          [ButtonIconPosition.LEFT]: "-ml-0",
          [ButtonIconPosition.RIGHT]: "ml-2 -mr-1"
        };
        const clsIconAppearance = {
          [ButtonAppearance.PRIMARY]: clsx(
            disabled ? "text-base-disabled dark:text-base-dark-disabled" : "text-neutral-0"
          ),
          [ButtonAppearance.SECONDARY]: clsx(
            disabled ? "text-base-disabled dark:text-base-dark-disabled" : "text-primary"
          ),
          [ButtonAppearance.RED]: clsx(disabled ? "text-base-disabled dark:text-base-dark-disabled" : "text-neutral-0"),
          [ButtonAppearance.TERTIARY]: "text-primary",
          [ButtonAppearance.NEUTRAL]: "text-primary"
        };

        if (!icon && !iconName) return null;

        if (iconName) {
          return (
            <IconWithName
              name={iconName as any}
              className={clsx(
                classNamePosition[iconPosition],
                clsIconSize[size],
                clsIconAppearance[appearance],
                classNameIcon
              )}
            />
          );
        }

        if (icon) {
          const IconComponent = icon;

          return (
            <IconComponent
              className={clsx(
                classNamePosition[iconPosition],
                clsIconSize[size],
                clsIconAppearance[appearance],
                classNameIcon
              )}
              aria-hidden="true"
            />
          );
        }
      },
      [size, appearance, disabled, classNameIcon]
    );

    if (isDataLoading) {
      return <Skeleton className={clsButtonSpacing[size]} />;
    }

    return React.cloneElement(
      as,
      {
        "data-testid": "Button",
        className: clsx(
          "inline-flex items-center justify-center rounded-md gap-x-1 text-primary scale-100 transition-transform active:scale-[0.98]",
          clsButtonSpacing[size],
          clsButtonStyling[appearance],
          className,
          !disabled && "cursor-pointer",
          disabled &&
            "!bg-neutral-200 dark:!bg-neutral-dark-200 !text-base-disabled dark:!text-base-dark-disabled !shadow-none"
        ),
        onClick: onClick,
        ref: mRef || ref,
        disabled,
        ...rest
      },
      <>
        {(icon || iconName) &&
          !isLoading &&
          iconPosition === ButtonIconPosition.LEFT &&
          renderIcon(icon, iconName, iconPosition)}
        {isLoading && <SpinnerIcon className={clsx(clsSpinnerSize, "-ml-1 mr-2 animate-spin")} />}
        {label}
        {(icon || iconName) &&
          !isLoading &&
          iconPosition === ButtonIconPosition.RIGHT &&
          renderIcon(icon, iconName, iconPosition)}
        {(!!count || showCountIndicator) && (
          <span
            className={clsx(
              "absolute right-[-5px] top-[-5px] flex h-3.5  w-3.5 items-center justify-center rounded-full bg-red-700 text-2xs font-semibold text-white dark:bg-red-dark-700"
            )}
          >
            {count}
          </span>
        )}
      </>
    );
  }
);

Button.displayName = "Button";

export default Button;
