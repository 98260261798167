"use client";

import React, { createContext, useCallback, useEffect, useState } from "react";
import { RecordItem } from "types/common";
import { ESCAPE_EVENT_RESOLVED_BY_COMP } from "utils/constants";
import toast from "utils/toast";

export type MENU_POSITION = "left" | "right" | "top";
export type UiState = {
  menuDirection?: "vertical" | "horizontal";
  isCollapsed?: boolean;
  menuPosition?: MENU_POSITION;
  vercelToolbarEnabled?: boolean;
};
export interface UIContextState {
  uiState?: UiState;
  updateUIState: (newPropValues: Partial<UiState>) => void;
  resetPageState: () => void;
  childBackPagePath: string | undefined; // When we redirect to parent detail page, go back uses this property
  updateChildBackPagePath: (path?: string) => void;
  escapeEventResolvedByComp?: ESCAPE_EVENT_RESOLVED_BY_COMP;
  setEscapeEventResolvedByComp: (comp?: ESCAPE_EVENT_RESOLVED_BY_COMP) => void;
  showErrorToast: (errorMessage: string, options?: RecordItem) => void;
  setVercelToolbarEnabled: (enabled: boolean) => void;
}

export const UIContext = createContext<UIContextState | null>(null);

const { Provider } = UIContext;
const VERCEL_TOOLBAR_ENABLED_KEY = "vercel_toolbar_enabled";

export const UIContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [uiState, setUIState] = useState<UiState>();
  const [childBackPagePath, setChildBackPagePath] = useState<string | undefined>();
  const [escapeEventResolvedByComp, setEscapeEventResolvedByComp] = useState<
    ESCAPE_EVENT_RESOLVED_BY_COMP | undefined
  >();

  const updateUIState = useCallback((newPropValues: Partial<UiState>) => {
    setUIState((prev) => {
      return {
        ...prev,
        ...newPropValues
      };
    });
  }, []);

  const resetPageState = useCallback(() => {
    setUIState({
      menuDirection: "vertical"
    });
  }, []);

  const updateChildBackPagePath = useCallback((path?: string) => {
    setChildBackPagePath(path);
  }, []);

  // This method will handle error display, currently set to use toast,
  //  but can be updated to use a different component if needed
  const showErrorToast = useCallback((errorMessage: string, options?: RecordItem) => {
    if (!errorMessage) return;
    toast.error(errorMessage, {
      autoClose: 10000,
      ...options
    });
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const isVercelToolbarEnabled = window.localStorage.getItem(VERCEL_TOOLBAR_ENABLED_KEY) === "true";
    updateUIState({ vercelToolbarEnabled: isVercelToolbarEnabled });
  }, [updateUIState]);

  const setVercelToolbarEnabled = useCallback(
    (enabled: boolean) => {
      window.localStorage.setItem(VERCEL_TOOLBAR_ENABLED_KEY, `${enabled}`);
      updateUIState({ vercelToolbarEnabled: enabled });
      if (enabled) {
        // reload the page when enabling the toolbar as it's needed for it to load properly
        window.location.reload();
      }
    },
    [updateUIState]
  );

  return (
    <Provider
      value={{
        uiState,
        updateUIState,
        resetPageState,
        childBackPagePath,
        updateChildBackPagePath,
        escapeEventResolvedByComp,
        setEscapeEventResolvedByComp,
        showErrorToast,
        setVercelToolbarEnabled
      }}
    >
      {children}
    </Provider>
  );
};
