import { useContext } from "react";
import { SchemaContext, SchemaState } from "context/SchemaContext";

const useSchemaState = () => {
  const dbState = useContext<SchemaState | null>(SchemaContext);

  if (typeof dbState === "undefined" || typeof dbState !== "object") {
    return {
      schema: null,
      updateSchema: () => {},
      schemaInstance: null,
      mergeSchema: () => {}, // Used to merge in schema fetched beyond public
      resetSchema: () => {},
      altSchemas: null
    };
  }

  return dbState as SchemaState;
};

export type SchemaContextType = ReturnType<typeof useSchemaState>;

export default useSchemaState;
