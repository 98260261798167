import { useMutation } from "@tanstack/react-query";
import { PostgrestError } from "@supabase/supabase-js";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { removeRecordByCompositePrimaryKey, removeRecordById } from "lib/supabaseApi";
import type { RemoveInput, RemoveResponse } from "types/apiTypes";
import { ERROR_TYPES } from "utils/constants";
import useErrorLogger from "./useErrorLogger";

const useRemoveRecord = () => {
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();
  const { mutateAsync, isPending } = useMutation<RemoveResponse, unknown, RemoveInput>({
    mutationFn: ({ tableName, id, compositePrimaryKey }: RemoveInput) =>
      compositePrimaryKey
        ? removeRecordByCompositePrimaryKey({ tableName, compositePrimaryKey }, supabaseClient)
        : removeRecordById({ tableName, id }, supabaseClient),
    onError: (error, variables) => {
      logError({
        error: error as PostgrestError,
        source: "useRemoveRecord",
        type: ERROR_TYPES.HOOKS,
        message:
          (error as PostgrestError).message || `Error removing record ${variables.id} from ${variables.tableName}`,
        url: window.location.href,
        additionalInfo: {
          variables
        }
      });
    }
  });

  return {
    removeRecordAsync: mutateAsync,
    isLoading: isPending
  };
};

export default useRemoveRecord;
