import { useMutation } from "@tanstack/react-query";
import { PostgrestError } from "@supabase/supabase-js";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { updateRecordById } from "lib/supabaseApi";
import type { UpdateInput, UpdateResponse } from "types/apiTypes";
import { ERROR_TYPES } from "utils/constants";
import useErrorLogger from "./useErrorLogger";

const useUpdateRecord = () => {
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();
  const { mutate, mutateAsync, isPending } = useMutation<UpdateResponse, unknown, UpdateInput>({
    mutationFn: ({ tableName, input }: UpdateInput) => updateRecordById({ tableName, input }, supabaseClient),
    onError: (error, variables) => {
      logError({
        error: error as PostgrestError,
        source: "useUpdateRecord",
        type: ERROR_TYPES.HOOKS,
        message: (error as PostgrestError).message || "Error updating records",
        url: window.location.href,
        additionalInfo: {
          variables
        }
      });
    }
  });

  return {
    updateRecordAsync: mutateAsync,
    updateRecord: mutate,
    isLoading: isPending
  };
};

export default useUpdateRecord;
